import { LocalizationContext } from '@dimatech/shared/lib/localization';
import { Language } from '@dimatech/shared/lib/models';
import { Breakpoints, Color, Theme } from '@dimatech/shared/lib/themes';
import { DimatechLogoHeader } from 'components/DimatechLogo';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import img_flag_en from 'assets/images/flag-en.png';
import img_flag_sv from 'assets/images/flag-sv.png';
import { Container } from 'components/Container';
import { Button } from './Button';

export const Header = (): JSX.Element => {
  const { t } = useTranslation();
  const { locale, changeLocale } = useContext(LocalizationContext);

  return (
    <Style>
      <Container>
        <DimatechLogoHeader />

        <div>
          <Navigation>
            <NavigationItem>
              <Link to="/pios">Pios</Link>
            </NavigationItem>
            <NavigationItem>
              <Link to="/dimios">Dimios</Link>
            </NavigationItem>
            <NavigationItem>
              <Link to="/dikios">Dikios</Link>
            </NavigationItem>
            <NavigationItem>
              <Link to="/dios">Dios</Link>
            </NavigationItem>
            <span>|</span>
            <NavigationItem>
              <Link to="/about">{t('Navigation.About')}</Link>
            </NavigationItem>
            <NavigationItem>
              <Link to="/policies"> {t('Navigation.Policies')}</Link>
            </NavigationItem>
            <Button
              className="hide-xs"
              text={t('Home.Newsletter.Title')}
              color={Color.DimatechBlue}
              handleClick={() => (window.location.href = '/newsletter')}
            />
            <NavigationItem className="show-xs">
              <Link to="/newsletter"> {t('Home.Newsletter.Title')}</Link>
            </NavigationItem>
          </Navigation>

          <ChangeLanguage
            type="button"
            onClick={() =>
              changeLocale(
                locale === Language.English
                  ? Language.Swedish
                  : Language.English
              )
            }
          >
            {locale === Language.English ? (
              <img src={img_flag_sv} alt="Swedish flag" />
            ) : (
              <img src={img_flag_en} alt="English flag" />
            )}
          </ChangeLanguage>
        </div>
      </Container>
    </Style>
  );
};

Header.displayName = 'Header';

const Style = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  > div {
    padding: 0 30px;

    > div:last-of-type {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;

      @media (max-width: ${Breakpoints.medium}) {
        gap: 10px;
      }

      @media (max-width: ${Breakpoints.small}) {
        gap: 20px;
        flex-basis: 100%;
        justify-content: space-between;

        padding: 10px;
        margin-bottom: 15px;

        background-color: ${({ theme }: { theme: Theme }) =>
          theme.colors.primary};
        color: ${({ theme }: { theme: Theme }) => theme.colors.onPrimary};
      }
    }
  }
`;

const Navigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;

  @media (max-width: ${Breakpoints.medium}) {
    margin-bottom: 5px;
    gap: 10px;
  }

  @media (max-width: ${Breakpoints.small}) {
    gap: 5px 10px;
    flex-grow: 10;
  }
`;

const NavigationItem = styled.div`
  a {
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};
    font-weight: 300;

    color: ${({ theme }: { theme: Theme }) => theme.colors.primary};
    border: none;

    @media (max-width: ${Breakpoints.small}) {
      color: ${({ theme }: { theme: Theme }) => theme.colors.onPrimary};
    }
  }
`;

const ChangeLanguage = styled.button`
  justify-self: flex-end;
  border: none;
  background: transparent;
  cursor: pointer;

  img {
    height: 24px;
  }
`;
