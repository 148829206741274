import i18next from 'i18next';
export var getPercentRemainder = function (value) {
    return value !== undefined && value !== null ? 100 - value : undefined;
};
export var toShortCurrency = function (value, formatter, shorts) {
    var k = 1000;
    if (value < k) {
        return "".concat(formatter(value), " ").concat(shorts[0].trim());
    }
    var i = Math.floor(Math.log(value) / Math.log(k));
    return "".concat(formatter(value / Math.pow(k, i)), " ").concat(shorts[i].trim());
};
export var formatAsNumber = function (value, defaultValue, decimals, language) {
    if (defaultValue === void 0) { defaultValue = '-'; }
    if (decimals === void 0) { decimals = 0; }
    if (language === void 0) { language = i18next.language; }
    var numeric = parseFloat((value || 0).toString());
    return isNaN(numeric) || numeric === 0
        ? defaultValue
        : numeric.toLocaleString(language, {
            maximumFractionDigits: decimals,
            minimumFractionDigits: decimals,
        });
};
export var formatAsPercent = function (value, defaultValue, decimals, language) {
    if (defaultValue === void 0) { defaultValue = '-'; }
    if (decimals === void 0) { decimals = 1; }
    if (language === void 0) { language = i18next.language; }
    var formattedValue = formatAsNumber(value, defaultValue, decimals, language);
    return formattedValue === null ? '' : "".concat(formattedValue, " %");
};
export var formatAsPercentRange = function (value) {
    if (value === undefined || isNaN(value)) {
        return '-';
    }
    var percent = formatAsPercent(value, '0', 0);
    var invertedPercent = formatAsPercent(100 - value, '0', 0);
    return "".concat(percent, " - ").concat(invertedPercent);
};
export var formatAsCurrency = function (value, language) {
    if (language === void 0) { language = i18next.language; }
    return formatAsNumber(value, '0', 0, language);
};
export var getDecimalSeparator = function (locale) {
    return getSeparator(locale, 'decimal');
};
export var getThousandSeparator = function (locale) {
    return getSeparator(locale, 'group');
};
export var getSeparator = function (locale, separatorType) {
    var _a;
    var numberWithGroupAndDecimalSeparator = 1000.1;
    return (_a = Intl.NumberFormat(locale)
        .formatToParts(numberWithGroupAndDecimalSeparator)
        .find(function (part) { return part.type === separatorType; })) === null || _a === void 0 ? void 0 : _a.value;
};
