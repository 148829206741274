import {
  Heading2,
  Heading3,
  Heading4,
} from '@dimatech/shared/lib/components/typography';
import { ProductPrimaryColor } from '@dimatech/shared/lib/themes';
import img_dimios_internal_comparison from 'assets/images/dimios-internal-comparison.png';
import img_dimios_maturity_staircase from 'assets/images/dimios-maturity-staircase.png';
import img_dimios_national_result from 'assets/images/dimios-national-result.png';
import img_dimios_product_hero from 'assets/images/dimios-product-hero.png';
import img_icon_analysis from 'assets/images/icon-analysis.png';
import img_icon_assessment from 'assets/images/icon-assessment.png';
import img_icon_respondents from 'assets/images/icon-respondents.png';
import { Button, Buttons, ButtonsHero } from 'components/Button';
import { Container, ContainerWrapperGridHighlight } from 'components/Container';
import { HeroSubTitle, HeroText, HeroTitle } from 'components/Hero';
import { HeroProduct } from 'components/HeroProduct';
import { HeroProductIntro } from 'components/HeroProductIntro';
import { InfoBox } from 'components/InfoBox';
import { Question } from 'components/Question';
import { Section } from 'components/Section';
import { View } from 'components/View';
import { Trans, useTranslation } from 'react-i18next';

import img_hero from 'assets/images/dimios-hero.jpg';

export const Dimios = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View>
      <HeroProduct image={img_hero}>
        <HeroTitle>{t('Dimios.Header.Title')}</HeroTitle>
        <HeroSubTitle>{t('Dimios.Header.SubTitle')}</HeroSubTitle>

        <ButtonsHero>
          <Button
            text={t('BookDemoButton.Text')}
            color={ProductPrimaryColor.Dimios}
            handleClick={() =>
              (window.location.href = `mailto:sales@dimatech.se?subject=${t(
                'RegisterButton.MailSubject',
                { product: 'Dimios' }
              )}`)
            }
          />

          <Button
            text={t('Dimios.Header.ButtonText')}
            color={ProductPrimaryColor.Dimios}
            handleClick={() =>
              (window.location.href = 'https://dimios.dimatech.se/register')
            }
            light
          />
        </ButtonsHero>
      </HeroProduct>

      <HeroProductIntro color={ProductPrimaryColor.Dimios}>
        <Container>
          <div>
            <HeroTitle>{t('Dimios.Intro.Title')}</HeroTitle>

            <HeroText style={{ maxWidth: 600, width: '100%' }}>
              {t('Dimios.Intro.Text')}
            </HeroText>

            <img
              src={img_dimios_product_hero}
              alt={t('Dimios.Header.Title')}
              style={{ maxHeight: 350, width: '100%' }}
            />
          </div>
        </Container>
      </HeroProductIntro>

      <Section>
        <Container>
          <div>
            <Heading2>{t('Dimios.Intro2.Title')}</Heading2>

            <div>
              <Trans i18nKey="Dimios.Intro2.Text" />
            </div>
          </div>
        </Container>
      </Section>

      <ContainerWrapperGridHighlight>
        <Container>
          <Heading3>{t('Dimios.InfoBox.Title')}</Heading3>
          <Heading4>{t('Dimios.InfoBox.SubTitle')}</Heading4>

          <div>
            <InfoBox
              image={img_icon_respondents}
              title={t('Dimios.InfoBox.Respondents.Title')}
            >
              <p>{t('Dimios.InfoBox.Respondents.Text')}</p>
            </InfoBox>

            <InfoBox
              image={img_icon_assessment}
              title={t('Dimios.InfoBox.Assessment.Title')}
            >
              <p>{t('Dimios.InfoBox.Assessment.Text')}</p>
            </InfoBox>

            <InfoBox
              image={img_icon_analysis}
              title={t('Dimios.InfoBox.Analysis.Title')}
            >
              <p>{t('Dimios.InfoBox.Analysis.Text')}</p>
            </InfoBox>
          </div>
        </Container>
      </ContainerWrapperGridHighlight>

      <Section style={{ marginBottom: 0 }}>
        <Container>
          <div>
            <Heading2>{t('Dimios.Information.Benefits.Title')}</Heading2>

            <div>
              <Trans i18nKey="Dimios.Information.Benefits.Text1" />
            </div>
          </div>

          <div className="image hide-xs">
            <img
              src={img_dimios_internal_comparison}
              alt="Dimios"
              className="no-shadow"
            />
          </div>
        </Container>
      </Section>

      <Section style={{ marginBottom: 0 }}>
        <Container>
          <div className="image-left hide-xs">
            <img
              src={img_dimios_national_result}
              alt="Dimios"
              className="no-shadow"
            />
          </div>
          <div>
            <div>
              <Trans i18nKey="Dimios.Information.Benefits.Text2" />
            </div>
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <div>
            <div>
              <Trans i18nKey="Dimios.Information.Benefits.Text3" />
            </div>
          </div>
          <div className="image hide-xs">
            <img
              src={img_dimios_maturity_staircase}
              alt="Dimios"
              className="no-shadow"
            />
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <div>
            <div>
              <Trans i18nKey="Dimios.Information.Summary.Text" />
            </div>

            <Buttons>
              <Button
                text={t('BookDemoButton.Text')}
                color={ProductPrimaryColor.Dimios}
                handleClick={() =>
                  (window.location.href = `mailto:sales@dimatech.se?subject=${t(
                    'RegisterButton.MailSubject',
                    { product: 'Dimios' }
                  )}`)
                }
              />

              <Button
                text={t('Dimios.Header.ButtonText')}
                color={ProductPrimaryColor.Dimios}
                handleClick={() =>
                  (window.location.href = 'https://dimios.dimatech.se/register')
                }
                light
              />
            </Buttons>
          </div>
          <div />
        </Container>
      </Section>

      <Section>
        <Container>
          <div>
            <Heading2 style={{ marginBottom: 30 }}>{t('Faq.Title')}</Heading2>

            <Question
              title={t('Faq.Dimios.Questions.DataStorage.Question')}
              content={t('Faq.Dimios.Questions.DataStorage.Answer')}
            />
            <Question
              title={t('Faq.Dimios.Questions.Gdpr.Question')}
              content={t('Faq.Dimios.Questions.Gdpr.Answer')}
            />
            <Question
              title={t('Faq.Dimios.Questions.DataCollection.Question')}
              content={t('Faq.Dimios.Questions.DataCollection.Answer')}
            />
            <Question
              title={t('Faq.Dimios.Questions.InfoPolicy.Question')}
              content={t('Faq.Dimios.Questions.InfoPolicy.Answer')}
            />
            <Question
              title={t('Faq.Dimios.Questions.ExportData.Question')}
              content={t('Faq.Dimios.Questions.ExportData.Answer')}
            />
            <Question
              title={t('Faq.Dimios.Questions.Price.Question')}
              content={t('Faq.Dimios.Questions.Price.Answer')}
            />
            <Question
              title={t('Faq.Dimios.Questions.HowToBuy.Question')}
              content={t('Faq.Dimios.Questions.HowToBuy.Answer')}
            />
            <Question
              title={t('Faq.Dimios.Questions.AccessNationalReports.Question')}
              content={t('Faq.Dimios.Questions.AccessNationalReports.Answer')}
            />
            <Question
              title={t('Faq.Dimios.Questions.GetHelpReadingResults.Question')}
              content={t('Faq.Dimios.Questions.GetHelpReadingResults.Answer')}
            />
            <Question
              title={t('Faq.Dimios.Questions.MeasureInterval.Question')}
              content={t('Faq.Dimios.Questions.MeasureInterval.Answer')}
            />
            <Question
              title={t('Faq.Dimios.Questions.CheaperDoingItYourself.Question')}
              content={t('Faq.Dimios.Questions.CheaperDoingItYourself.Answer')}
            />
            <Question
              title={t('Faq.Dimios.Questions.PersonalAgreement.Question')}
              content={t('Faq.Dimios.Questions.PersonalAgreement.Answer')}
            />
          </div>
        </Container>
      </Section>
    </View>
  );
};

Dimios.displayName = 'Dimios';
