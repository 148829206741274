export var SurveyName;
(function (SurveyName) {
    SurveyName["Dimios"] = "Dimios";
    SurveyName["Dikios"] = "Dikios";
})(SurveyName || (SurveyName = {}));
export var SurveyType;
(function (SurveyType) {
    SurveyType["User"] = "User";
    SurveyType["Entity"] = "Entity";
})(SurveyType || (SurveyType = {}));
export var SurveyMessageType;
(function (SurveyMessageType) {
    SurveyMessageType["SurveyMessage"] = "SurveyMessage";
    SurveyMessageType["SurveyReminder"] = "SurveyReminder";
})(SurveyMessageType || (SurveyMessageType = {}));
export var SurveyStatus;
(function (SurveyStatus) {
    SurveyStatus["None"] = "None";
    SurveyStatus["Opened"] = "Opened";
    SurveyStatus["Started"] = "Started";
    SurveyStatus["Finished"] = "Finished";
})(SurveyStatus || (SurveyStatus = {}));
export var SurveyResponseStatus;
(function (SurveyResponseStatus) {
    SurveyResponseStatus["None"] = "None";
    SurveyResponseStatus["Opened"] = "Opened";
    SurveyResponseStatus["Started"] = "Started";
    SurveyResponseStatus["Finished"] = "Finished";
})(SurveyResponseStatus || (SurveyResponseStatus = {}));
export var ScheduleStatus;
(function (ScheduleStatus) {
    ScheduleStatus["Started"] = "Started";
    ScheduleStatus["NotStarted"] = "NotStarted";
    ScheduleStatus["Closed"] = "Closed";
    ScheduleStatus["Paused"] = "Paused";
})(ScheduleStatus || (ScheduleStatus = {}));
export var RespondentMessageStatus;
(function (RespondentMessageStatus) {
    RespondentMessageStatus["Scheduled"] = "Scheduled";
    RespondentMessageStatus["InProgress"] = "InProgress";
    RespondentMessageStatus["Sent"] = "Sent";
    RespondentMessageStatus["Error"] = "Error";
    RespondentMessageStatus["Delivered"] = "Delivered";
    // TODO: Fix spelling, need to update DB and backend for this
    RespondentMessageStatus["Deffered"] = "Deffered";
    RespondentMessageStatus["Blocked"] = "Blocked";
    RespondentMessageStatus["Dropped"] = "Dropped";
    RespondentMessageStatus["Bounced"] = "Bounced";
    RespondentMessageStatus["Opened"] = "Opened";
})(RespondentMessageStatus || (RespondentMessageStatus = {}));
