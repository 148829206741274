var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Heading1 = styled.h1(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font: ", ";\n"], ["\n  font: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.h1;
});
export var Heading2 = styled.h2(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font: ", ";\n"], ["\n  font: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.h2;
});
export var Heading3 = styled.h3(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font: ", ";\n"], ["\n  font: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.h3;
});
export var Heading4 = styled.h4(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font: ", ";\n"], ["\n  font: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.h4;
});
export var Heading5 = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: bold;\n\n  padding-bottom: 2px;\n  margin-bottom: 5px;\n"], ["\n  font-weight: bold;\n\n  padding-bottom: 2px;\n  margin-bottom: 5px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
