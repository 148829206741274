var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../config';
import { getSubdomain, hyphenToCamelCase, toTitleCase } from '../utils';
export var ApplicationContext = createContext({});
export var ApplicationProvider = function (_a) {
    var children = _a.children;
    var t = useTranslation().t;
    var subdomain = getSubdomain(window.location.hostname) || config.defaultSubdomain;
    var title = t("Account.".concat(hyphenToCamelCase(toTitleCase(subdomain)), ".Name"));
    return (_jsx(ApplicationContext.Provider, __assign({ value: {
            subdomain: subdomain,
            title: title,
        } }, { children: children })));
};
