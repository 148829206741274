var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Radio = styled.input.attrs(function () { return ({
    type: 'radio',
}); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 2px;\n  position: relative;\n  width: 18px;\n  height: 18px;\n  appearance: none;\n  outline: none;\n  border-radius: 50%;\n  background-color: ", ";\n\n  border: 1px solid\n    ", ";\n\n  cursor: pointer;\n\n  &:checked {\n    &:after {\n      content: '';\n      position: absolute;\n      top: 50%;\n      left: 50%;\n      transform: translate(-50%, -50%);\n      pointer-events: none;\n      width: 10px;\n      height: 10px;\n      border-radius: 50%;\n\n      background-color: ", ";\n\n      border: 0px solid ", ";\n    }\n  }\n\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  margin-top: 2px;\n  position: relative;\n  width: 18px;\n  height: 18px;\n  appearance: none;\n  outline: none;\n  border-radius: 50%;\n  background-color: ", ";\n\n  border: 1px solid\n    ", ";\n\n  cursor: pointer;\n\n  &:checked {\n    &:after {\n      content: '';\n      position: absolute;\n      top: 50%;\n      left: 50%;\n      transform: translate(-50%, -50%);\n      pointer-events: none;\n      width: 10px;\n      height: 10px;\n      border-radius: 50%;\n\n      background-color: ", ";\n\n      border: 0px solid ", ";\n    }\n  }\n\n  &:hover {\n    background-color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (props) {
    return props.invalid
        ? props.theme.colors.error
        : props.disabled || props.readOnly
            ? props.theme.colors.dim
            : props.theme.colors.secondary;
}, function (props) {
    return props.invalid
        ? props.theme.colors.error
        : props.disabled || props.readOnly
            ? props.theme.colors.dim
            : props.theme.colors.secondary;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
});
Radio.displayName = 'Radio';
var templateObject_1;
