var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import sanitizeHtml from 'sanitize-html';
import { config } from '../config';
import { CountryCode } from '../models';
import { Color } from '../themes';
import { stringToColor } from '../utils';
export var toTitleCase = function (str, preserveCase) {
    if (preserveCase === void 0) { preserveCase = false; }
    return (str === null || str === void 0 ? void 0 : str.replace(/\w\S*/g, function (txt) {
        return "".concat(txt === null || txt === void 0 ? void 0 : txt.charAt(0).toUpperCase()).concat(preserveCase ? txt === null || txt === void 0 ? void 0 : txt.substring(1) : txt === null || txt === void 0 ? void 0 : txt.substring(1).toLowerCase());
    })) || '';
};
export var hyphenToCamelCase = function (str) {
    return (str === null || str === void 0 ? void 0 : str.replace(/-./g, function (txt) { return txt.charAt(1).toUpperCase(); })) || '';
};
export var initialsFromEmail = function (email) {
    return "".concat(email === null || email === void 0 ? void 0 : email.substring(0, 1).toUpperCase()).concat((email === null || email === void 0 ? void 0 : email.includes('.'))
        ? email === null || email === void 0 ? void 0 : email.split('.')[1].substring(0, 1).toUpperCase()
        : '');
};
export var colorFromEmail = function (_a) {
    var email = _a.email, currentUserEmail = _a.currentUserEmail;
    return !email || email === null || email === config.support.email
        ? Color.DimatechBlue
        : currentUserEmail === email
            ? null
            : stringToColor(email);
};
/**
 * Safely decode string to be displayed on a page without side effects
 * See https://developer.mozilla.org/en-US/docs/Web/API/DOMParser
 * and https://stackoverflow.com/a/34064434/202464
 * @param str
 * @param extraTags
 * @returns
 */
export var htmlDecode = function (str, extraTags) {
    return sanitizeHtml(str, {
        allowedTags: __spreadArray([
            'br',
            'li',
            'ul',
            'ol',
            'strong',
            'em',
            'i',
            'p'
        ], (extraTags !== null && extraTags !== void 0 ? extraTags : []), true),
        allowedAttributes: {
            a: ['href'],
        },
    });
};
/**
 * Basic email validation
 * @param str
 * @returns
 */
export var isValidEmail = function (str) {
    // Verifies that str has
    // - only one @
    // - at least one character before the @, before the period and after it:
    return /^[^@]+@[^@]+\.[^@]+$/.test(str);
};
/**
 * Basic password validation
 * @param str
 * @returns
 */
export var isValidPassword = function (str) {
    // Verifies that str has
    // -min length 6, number from 0-9, lowercase letter, uppercase letter, non-alphanumeric character
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).{6,}$/.test(str);
};
/**
 * Basic password validation
 * @param str
 * @returns
 */
export var isValidLegacyPassword = function (str) {
    // Verifies that str has
    // -min length 6, number from 0-9, lowercase letter, uppercase letter or non-alphanumeric character
    // cspell:disable-next-line
    return /^(?=.*[^a-zåäöA-ZÅÄÖ])(?=.*[a-z])(?=.*[A-ZÅÄÖ])\S{6,}$/.test(str);
};
/**
 * Basic orgNumber validation
 * @param str
 * @param countryCode
 * @returns
 */
export var isValidOrgNumber = function (str, countryCode) {
    // If countryCode is not supplied or is SE, then validate orgNumber
    if (!countryCode || countryCode === CountryCode.SE) {
        // Verifies that str consists of 6 digits space/hyphen 4 digits
        return /^\d{6}[\s-]\d{4}$/.test(str);
    }
    // If countryCode is supplied and is not SE, then we don't validate the orgNumber
    return true;
};
/**
 * Basic areaCode validation
 * @param str
 * @returns
 */
export var isValidAreaCode = function (str, countryCode) {
    // If countryCode is not supplied or is SE, then validate
    if (!countryCode || countryCode === CountryCode.SE) {
        // Verifies that str consist of 3 digits space/no space 2 digits
        return /^\d{3}[\s]{0,1}\d{2}$/.test(str);
    }
    // If countryCode is supplied and is not SE, then we don't validate
    return true;
};
/**
 * Check if value is a boolean (true or false)
 * @param value
 * @returns true if value is a boolean
 */
export var isBoolean = function (value) {
    return ['true', 'false'].includes(value === null || value === void 0 ? void 0 : value.toString().toLowerCase());
};
/**
 * Basic html validation
 * @param str
 * @returns
 */
export var isHtmlNotEmpty = function (str) {
    // Verifies that html is not empty
    return /(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)|<br>|<p>(&nbsp;)+<\/p>/.test(str);
};
/**
 * Extraction text from HTML
 * @param str
 * @returns
 */
export var extractTextFromHTML = function (str) {
    var doc = new DOMParser().parseFromString(str || '', 'text/html');
    return doc.body.textContent || '';
};
export var shorten = function (str, maxLength, ellipse) {
    if (ellipse === void 0) { ellipse = '...'; }
    if (!str) {
        return str;
    }
    var textLength = str.length;
    var shortened = str.substring(0, maxLength);
    return "".concat(shortened).concat(textLength > maxLength ? ellipse : '');
};
export var nameOf = function (name) { return name; };
