var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { LoaderContent, LoaderStyle } from './Loader';
export var LoaderSmall = function (_a) {
    var children = _a.children, style = _a.style;
    return (_jsxs(Container, { children: [_jsx(Style, { style: style }), children && _jsx(Content, { children: children })] }));
};
LoaderSmall.displayName = 'LoaderSmall';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var Style = styled(LoaderStyle)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 20px;\n  height: 20px;\n\n  border: 3px solid ", ";\n  border-top: 3px solid\n    ", ";\n"], ["\n  width: 20px;\n  height: 20px;\n\n  border: 3px solid ", ";\n  border-top: 3px solid\n    ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.secondary;
});
var Content = styled(LoaderContent)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n"], ["\n  font-size: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.s;
});
var templateObject_1, templateObject_2, templateObject_3;
