import { Theme } from '@dimatech/shared/lib/themes';
import styled, { withTheme } from 'styled-components';

export const Divider = withTheme(
  ({ color, theme }: { color?: string; theme: Theme }): JSX.Element => {
    return <Style color={color ?? theme.colors.primary} />;
  }
);

Divider.displayName = 'Divider';

const Style = styled.div<{
  readonly color: string;
}>`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.color};
`;
