import img_icon_lock from 'assets/images/icon-lock.png';
import img_icon_user_terms from 'assets/images/icon-user-terms.png';
import { Container, ContainerWrapperGrid } from 'components/Container';
import { PoliciesBox } from 'components/PoliciesBox';
import { View } from 'components/View';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Policies = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View>
      <PoliciesBox />

      <Info>
        <Container>
          <div>
            <ContainerCell>
              <img
                src={img_icon_lock}
                alt={t('Policies.PoliciesBox.Policies.Text')}
              />
              <div>
                <h3>{t('Policies.PoliciesBox.Policies.Title')}</h3>
                <Link to="/policies-read-more" className="policies-link">
                  {t('Policies.PoliciesBox.Policies.LinkText')}
                </Link>
              </div>
            </ContainerCell>

            <ContainerCell>
              <img
                src={img_icon_user_terms}
                alt={t('Policies.PoliciesBox.UserTerms.Title')}
              />
              <div>
                <h3>{t('Policies.PoliciesBox.UserTerms.Title')}</h3>
                <Link to="/user-terms-read-more" className="policies-link">
                  {t('Policies.PoliciesBox.UserTerms.LinkText')}
                </Link>
              </div>
            </ContainerCell>
          </div>
        </Container>
      </Info>
    </View>
  );
};

const Info = styled(ContainerWrapperGrid)`
  > div > div {
    display: flex;
    justify-content: space-around;
  }
`;

const ContainerCell = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  justify-content: flex-start;
  gap: 20px;

  text-align: center;

  max-width: 400px;
  padding: 0 20px;

  h3 {
    margin-bottom: 10px;
  }
`;
