var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useId } from 'react';
import ReactSwitch from 'react-switch';
import { withTheme } from 'styled-components';
import { TooltipContent } from '../tooltip';
export var Switch = withTheme(function (_a) {
    var label = _a.label, tooltipTitle = _a.tooltipTitle, tooltipText = _a.tooltipText, theme = _a.theme, isChecked = _a.isChecked, setIsChecked = _a.setIsChecked, _b = _a.isLabelRightAligned, isLabelRightAligned = _b === void 0 ? false : _b, _c = _a.isDisabled, isDisabled = _c === void 0 ? false : _c, style = _a.style;
    var id = useId();
    return (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt-".concat(id), title: tooltipTitle, text: tooltipText }), _jsxs("label", __assign({ style: __assign({ display: 'flex', flexDirection: isLabelRightAligned ? 'row-reverse' : 'row', width: 'fit-content' }, style) }, { children: [_jsx("div", __assign({ style: {
                            marginLeft: isLabelRightAligned ? 5 : 0,
                            marginRight: isLabelRightAligned ? 0 : 5,
                            marginTop: 1,
                            whiteSpace: 'nowrap',
                        }, "data-tip": true, "data-for": "tt-".concat(id) }, { children: label })), _jsx(ReactSwitch, { height: 24, width: 45, onColor: theme.colors.primary, uncheckedIcon: false, checkedIcon: false, onChange: function (checked) { return setIsChecked(checked); }, checked: isChecked, disabled: isDisabled })] }))] }));
});
Switch.displayName = 'Switch';
