var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from 'styled-components';
export var formField = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  background-color: ", ";\n\n  ", "\n\n  border-radius: 0px;\n  border: none;\n\n  border-bottom: 1px solid\n    ", ";\n\n  ", "\n"], ["\n  color: ", ";\n  background-color: ", ";\n\n  ", "\n\n  border-radius: 0px;\n  border: none;\n\n  border-bottom: 1px solid\n    ", ";\n\n  ", "\n"])), function (props) {
    return props.disabled || props.readOnly
        ? props.theme.colors.dim
        : props.theme.colors.onSurface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (props) {
    return props.inverted && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      color: ", ";\n      background-color: ", ";\n\n      ::placeholder {\n        color: ", ";\n        opacity: 1;\n      }\n    "], ["\n      color: ", ";\n      background-color: ", ";\n\n      ::placeholder {\n        color: ", ";\n        opacity: 1;\n      }\n    "])), props.disabled || props.readOnly
        ? props.theme.colors.dim
        : props.theme.colors.onBackground, props.theme.colors.background, function (_a) {
        var theme = _a.theme;
        return theme.colors.onBackground;
    });
}, function (props) {
    return props.invalid
        ? props.theme.colors.error
        : props.disabled || props.readOnly
            ? props.theme.colors.dim
            : props.theme.colors.secondary;
}, function (props) {
    return !props.disabled &&
        !props.readOnly && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      &:hover {\n        color: ", ";\n        background-color: ", ";\n\n        border-radius: 3px;\n      }\n    "], ["\n      &:hover {\n        color: ", ";\n        background-color: ", ";\n\n        border-radius: 3px;\n      }\n    "])), function (_a) {
        var theme = _a.theme;
        return theme.colors.onBackground;
    }, function (_a) {
        var theme = _a.theme;
        return theme.colors.background;
    });
});
var templateObject_1, templateObject_2, templateObject_3;
