import { Heading2 } from '@dimatech/shared/lib/components/typography';
import img_about_team_andreas from 'assets/images/about-team-andreas.png';
import img_about_team_mikael from 'assets/images/about-team-mikael.png';
import img_about_team_tobias from 'assets/images/about-team-tobias.png';
import {
  Container,
  ContainerWrapper,
  ContainerWrapperGrid,
} from 'components/Container';
import { Divider } from 'components/Divider';
import { HeadingTagLine } from 'components/HeadingTagLine';
import { Hero } from 'components/Hero';
import { View } from 'components/View';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import img_hero from 'assets/images/about-hero.jpg';
import { Section } from 'components/Section';

export const About = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View>
      <Hero text={t('Home.Hero.Text')} image={img_hero} />

      <Section>
        <Container>
          <HeadingTagLine>{t('About.TagLiner')}</HeadingTagLine>
        </Container>

        <Container style={{ alignItems: 'flex-start' }}>
          <div style={{ flexGrow: 1, flexBasis: '30%' }}>
            <Heading2>{t('About.DigitalMaturity.Title')}</Heading2>
          </div>
          <div style={{ flexGrow: 2, flexBasis: '70%' }}>
            <div>{t('About.DigitalMaturity.Text')}</div>
            <p>{t('About.DigitalMaturity.Text2')}</p>
          </div>
        </Container>

        <Container style={{ alignItems: 'flex-start', marginTop: 50 }}>
          <div style={{ flexGrow: 1, flexBasis: '30%' }}>
            <Heading2>{t('About.AboutUs.Title')}</Heading2>
          </div>
          <div style={{ flexGrow: 2, flexBasis: '70%' }}>
            <div>{t('About.AboutUs.Text')}</div>
            <p>{t('About.AboutUs.Text2')}</p>
            <p>{t('About.AboutUs.Text3')}</p>
          </div>
        </Container>
      </Section>

      <ContainerWrapper>
        <Container>
          <Divider />
        </Container>
      </ContainerWrapper>

      <ContainerWrapperGrid>
        <Container style={{ padding: '0 30px' }}>
          <Heading2>{t('About.DimatechTeam.Title')}</Heading2>
        </Container>

        <Container style={{ padding: '0 30px' }}>
          <DimatechTeam>
            <div>
              <DimatechTeamMember
                src={img_about_team_andreas}
                alt="Andreas Nilsson"
              />
              <div>
                <p className="b">Andreas Nilsson</p>
                <p>{t('About.ProfessionalTitle.Andreas')}</p>
              </div>
            </div>

            <div>
              <DimatechTeamMember
                src={img_about_team_mikael}
                alt="Mikael Nygren"
              />
              <div>
                <p className="b">Mikael Nygren</p>
                <p>{t('About.ProfessionalTitle.Mikael')}</p>
              </div>
            </div>

            <div>
              <DimatechTeamMember
                src={img_about_team_tobias}
                alt="Tobias Ladhe"
              />
              <div>
                <p className="b">Tobias Ladhe</p>
                <p>{t('About.ProfessionalTitle.Tobias')}</p>
              </div>
            </div>
          </DimatechTeam>
        </Container>
      </ContainerWrapperGrid>
    </View>
  );
};

About.displayName = 'About';

const DimatechTeam = styled.div`
  justify-content: space-between !important;
  align-items: flex-start !important;
  gap: 20px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;

    max-width: 270px;
    min-width: 200px;
    width: 100%;

    gap: 20px;
  }
`;

const DimatechTeamMember = styled.img`
  max-width: 270px;
  max-height: 280px;
  width: 100%;
`;
