export var Translations;
(function (Translations) {
    Translations["Common"] = "Common";
})(Translations || (Translations = {}));
export var Currency;
(function (Currency) {
    Currency["SEK"] = "SEK";
    Currency["EUR"] = "EUR";
})(Currency || (Currency = {}));
export var Language;
(function (Language) {
    Language["Swedish"] = "sv";
    Language["English"] = "en";
})(Language || (Language = {}));
export var CountryCode;
(function (CountryCode) {
    // Denmark
    CountryCode["DK"] = "DK";
    // Finland
    CountryCode["FI"] = "FI";
    // Norway
    CountryCode["NO"] = "NO";
    // Sweden
    CountryCode["SE"] = "SE";
    // Åland Islands
    CountryCode["AX"] = "AX";
})(CountryCode || (CountryCode = {}));
export var Locale;
(function (Locale) {
    // Swedish (Sweden)
    Locale["sv_SE"] = "sv-SE";
    // English (Great Britain)
    Locale["en_GB"] = "en-GB";
    // Swedish (Åland Islands)
    Locale["sv_AX"] = "sv-AX";
    // Swedish (Finland)
    Locale["sv_FI"] = "sv-FI";
})(Locale || (Locale = {}));
