export var Environment;
(function (Environment) {
    Environment["Development"] = "development";
    Environment["Test"] = "test";
    Environment["Staging"] = "staging";
    Environment["Production"] = "production";
})(Environment || (Environment = {}));
export var NodeEnvironment;
(function (NodeEnvironment) {
    NodeEnvironment["Development"] = "development";
    NodeEnvironment["Test"] = "test";
    NodeEnvironment["Production"] = "production";
})(NodeEnvironment || (NodeEnvironment = {}));
export var DeployEnvironment;
(function (DeployEnvironment) {
    DeployEnvironment["Development"] = "Dev";
    DeployEnvironment["Staging"] = "Staging";
    DeployEnvironment["Production"] = "Prod";
})(DeployEnvironment || (DeployEnvironment = {}));
