// Allow any here since it can be of any type
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { add, differenceInCalendarDays, format, formatDistance, formatRelative, isDate, isFuture, isPast, set, } from 'date-fns';
import { enGB as en, sv } from 'date-fns/locale';
import i18next from 'i18next';
import { Language } from '../models';
// See: https://date-fns.org/v2.16.1/docs/format#arguments
export var formatDate = function (value, formatter, language) {
    if (formatter === void 0) { formatter = 'date-time'; }
    if (language === void 0) { language = i18next.language; }
    language = language !== null && language !== void 0 ? language : Language.Swedish;
    if (isDate(value)) {
        var locales = { en: en, sv: sv };
        var locale = locales[language];
        if (formatter === 'date') {
            return format(value, 'P', { locale: locale });
        }
        if (formatter === 'date-time') {
            return format(value, 'Pp', { locale: locale });
        }
        if (formatter === 'time') {
            return format(value, 'p', { locale: locale });
        }
        if (formatter === 'year') {
            return format(value, 'yyyy', { locale: locale });
        }
        if (formatter === 'weekday') {
            return format(value, 'EEEE', { locale: locale });
        }
        if (formatter === 'relative') {
            return formatRelative(value, new Date(), { locale: locale });
        }
        if (formatter === 'ago') {
            return formatDistance(value, new Date(), {
                locale: locale,
                addSuffix: true,
            });
        }
        return format(value, formatter || 'Pp', { locale: locale });
    }
    return value;
};
export var getCommonYears = function (yearsBack, yearsForward) {
    if (yearsBack === void 0) { yearsBack = 7; }
    if (yearsForward === void 0) { yearsForward = 5; }
    var years = [];
    var now = new Date().getFullYear();
    var date = new Date(now + yearsForward, 0, 0);
    for (var i = 0; i < yearsBack + yearsForward; i++) {
        date.setFullYear(date.getFullYear() - 1);
        years.push(date.getFullYear());
    }
    return years;
};
//Calculates all swedish holidays for a given year
export var getSwedishHolidays = function (date) {
    var holidays = [];
    var year = date.getFullYear();
    // All saints day, Easter eve, Pentecost day/eve and Midsummers day is always on weekends so we can ignore those
    // Easter sunday is the base for several holidays such as ascension day and good friday.
    var easterSunday = getEasterSunday(year);
    // New years day (Nyårsdagen)
    holidays.push(new Date(year, 1, 1));
    // Epiphany day (Trettondagen)
    holidays.push(new Date(year, 1, 6));
    // Good friday, first friday before easter (Långfredag)
    holidays.push(add(easterSunday, { days: -3 }));
    // Easter monday, day after easter day (Annandag påsk)
    holidays.push(add(easterSunday, { days: 1 }));
    // First of may, since 1939 (Första maj)
    if (year > 1938)
        holidays.push(new Date(year, 5, 1));
    // Pentecost boxing day, 50 days after easter sunday, until 2005 (Annandag pingst)
    if (year < 2005)
        holidays.push(add(easterSunday, { days: 50 }));
    // Swedish national day, since 2005 (Nationaldagen)
    if (year > 2004)
        holidays.push(new Date(year, 6, 6));
    // Ascension day, seventh thursday after easter day (Kristihimmelsfärd)
    holidays.push(add(easterSunday, { days: 5 * 7 + 4 }));
    // Midsummer eve, day before the saturday occuring between 20th and 26th of june (Midsommarafton)
    var midsummersEve = new Date(year, 6, 20);
    while (midsummersEve.getDay() != 6)
        // Saturday
        midsummersEve = add(midsummersEve, { days: 1 });
    holidays.push(add(midsummersEve, { days: -1 }));
    // Christmas eve (Julafton)
    holidays.push(new Date(year, 12, 24));
    // Christmas day (Juldagen)
    holidays.push(new Date(year, 12, 25));
    // Boxing day (Annandag jul)
    holidays.push(new Date(year, 12, 26));
    // New years eve (Nyårsafton)
    holidays.push(new Date(year, 12, 31));
    return holidays;
};
// Calculates the date for easter sunday for the given year.
// In principle, the date of easter sunday is defined as the sunday following the full moon
// following vernal equinox (the so-called paschal full moon, occuring around 20/21th of march).
export var getEasterSunday = function (year) {
    /*
    This algorithm is based in part on the algorithm of Oudin (1940) as quoted in
    "Explanatory Supplement to the Astronomical Almanac", P. Kenneth Seidelmann.
  
    g is the Golden Number-1
    h is 23-Epact (modulo 30)
    i is the number of days from 21 March to the Paschal full moon
    j is the weekday for the Paschal full moon (0=Sunday, 1=Monday, etc.)
    l is the number of days from 21 March to the Sunday on or before the Paschal full moon (a number between -6 and 28)
    c is then Century  */
    var g = year % 19;
    var c = year / 100;
    var x = c / 4;
    var y = (8 * c + 13) / 25;
    var z = 19 * g;
    var h = (c - x - y + z + 15) % 30;
    x = h / 28;
    y = 29 / (h + 1);
    z = (21 - g) / 11;
    var i = h - x * (1 - y * z);
    x = year / 4;
    y = c / 4;
    var j = (year + x + i + 2 - c + y) % 7;
    var l = i - j;
    x = (l + 40) / 44;
    var month = 3 + x;
    x = month / 4;
    var day = l + 28 - 31 * x;
    return new Date(year, month, day);
};
// Withraws N days from given date, excluding swedish holidays and weekends
export var withdrawSwedishBankDays = function (date, bankDays) {
    var year = date.getFullYear();
    var holidays = getSwedishHolidays(date);
    while (bankDays > 0) {
        date = add(date, { days: -1 });
        // If year has changed we need to re-calculate holidays for the new year
        if (year != date.getFullYear()) {
            holidays = getSwedishHolidays(date);
            year = date.getFullYear();
        }
        if (date.getDay() != 6 && // Saturday
            date.getDay() != 0 && // Sunday
            !holidays.includes(date)) {
            bankDays--;
        }
    }
    return date;
};
// Retrieves the next valid bank day or the date given if it is a valid bank day.
export var getNextSwedishBankDay = function (date) {
    var holidays = getSwedishHolidays(date);
    if (holidays.includes(date) ||
        date.getDay() == 6 || // Saturday
        date.getDay() == 0 // Sunday
    ) {
        return getNextSwedishBankDay(add(date, { days: 1 }));
    }
    return date;
};
// We sometimes suggest start date to be
// the next swedish bank day at defined hours
export var nextBankDayAtHours = function (hours) {
    return set(getNextSwedishBankDay(add(new Date(), {
        days: 1,
    })), {
        hours: hours,
        minutes: 0,
        seconds: 0,
    });
};
// Mock date for testing purposes
export var mockDate = function (expected) {
    var _date = Date;
    var MockDate = function (mockOverride) {
        return new _date(mockOverride || expected);
    };
    MockDate.UTC = _date.UTC;
    MockDate.parse = _date.parse;
    MockDate.now = function () { return expected.getTime(); };
    MockDate.prototype = _date.prototype;
    global.Date = MockDate;
    return function () {
        global.Date = _date;
    };
};
export var daysLeft = function (startDate, endDate) {
    if (!startDate || !endDate) {
        return null;
    }
    var start = new Date(startDate);
    var end = new Date(endDate);
    return isPast(start) && isFuture(end)
        ? differenceInCalendarDays(end, new Date())
        : null;
};
