/**
 * Merges two json files into one
 * A simplified version of https://lodash.com/docs/4.17.15#merge mostly
 * used for merging language files together
 * @param source
 * @param extender
 * @returns
 */
export var mergeJson = function (source, extenders) {
    var result = source;
    extenders.forEach(function (extender) {
        result = mergeFiles(result, extender);
    });
    return result;
};
var mergeFiles = function (source, extender) {
    var _a;
    var result = source;
    for (var key in extender) {
        if (Object.prototype.hasOwnProperty.call(extender, key)) {
            if (typeof extender[key] === 'string') {
                result[key] = extender[key];
            }
            else {
                result[key] = mergeFiles(((_a = source[key]) !== null && _a !== void 0 ? _a : {}), extender[key]);
            }
        }
    }
    return result;
};
