var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Sorter } from '../sorter';
export var Th = function (_a) {
    var sorter = _a.sorter, sortKey = _a.sortKey, sortPredicate = _a.sortPredicate, children = _a.children, style = _a.style, colSpan = _a.colSpan, className = _a.className, isExportIgnored = _a.isExportIgnored;
    return !!sorter && sortKey ? (_jsx(Style, __assign({ className: className, colSpan: colSpan, style: __assign({ cursor: 'pointer', overflow: 'hidden' }, style), onClick: function () { return sorter.requestSort(sortKey, sortPredicate); }, "data-export-ignore": isExportIgnored }, { children: _jsxs("div", { children: [children, _jsx(Sorter, { sorter: sorter, sortKey: sortKey })] }) }))) : (_jsx(Style, __assign({ colSpan: colSpan, style: style, "data-export-ignore": isExportIgnored }, { children: _jsx("span", { children: children }) })));
};
Th.displayName = 'Th';
var Style = styled.th(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  text-align: left;\n  vertical-align: bottom;\n  padding: 5px 10px;\n\n  &.narrow {\n    padding: 5px 5px;\n  }\n\n  border-bottom: 1px solid\n    ", ";\n\n  > div {\n    display: flex;\n\n    > span {\n      margin-left: 5px;\n      width: 15px;\n      display: inline-block;\n    }\n  }\n"], ["\n  text-align: left;\n  vertical-align: bottom;\n  padding: 5px 10px;\n\n  &.narrow {\n    padding: 5px 5px;\n  }\n\n  border-bottom: 1px solid\n    ", ";\n\n  > div {\n    display: flex;\n\n    > span {\n      margin-left: 5px;\n      width: 15px;\n      display: inline-block;\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.border;
});
export var ThCentered = function (_a) {
    var sorter = _a.sorter, sortKey = _a.sortKey, sortPredicate = _a.sortPredicate, children = _a.children, style = _a.style, colSpan = _a.colSpan, className = _a.className;
    return !!sorter && sortKey ? (_jsx(StyleCentered, __assign({ className: className, colSpan: colSpan, style: __assign({ cursor: 'pointer', overflow: 'hidden' }, style), onClick: function () { return sorter.requestSort(sortKey, sortPredicate); } }, { children: _jsxs("div", { children: [children, _jsx(Sorter, { sorter: sorter, sortKey: sortKey })] }) }))) : (_jsx(StyleCentered, __assign({ colSpan: colSpan, style: style }, { children: _jsx("span", { children: children }) })));
};
var StyleCentered = styled(Style)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: center;\n\n  > div {\n    justify-content: center;\n  }\n"], ["\n  text-align: center;\n\n  > div {\n    justify-content: center;\n  }\n"])));
ThCentered.displayName = 'ThCentered';
export var ThRight = function (_a) {
    var sorter = _a.sorter, sortKey = _a.sortKey, sortPredicate = _a.sortPredicate, children = _a.children, style = _a.style, colSpan = _a.colSpan, className = _a.className, isExportIgnored = _a.isExportIgnored;
    return !!sorter && sortKey ? (_jsx(StyleRight, __assign({ className: className, colSpan: colSpan, style: __assign({ cursor: 'pointer', overflow: 'hidden' }, style), onClick: function () { return sorter.requestSort(sortKey, sortPredicate); }, "data-export-ignore": isExportIgnored }, { children: _jsxs("div", { children: [_jsx(Sorter, { sorter: sorter, sortKey: sortKey }), children] }) }))) : (_jsx(StyleRight, __assign({ colSpan: colSpan, style: style, "data-export-ignore": isExportIgnored }, { children: _jsx("span", { children: children }) })));
};
ThRight.displayName = 'ThRight';
var StyleRight = styled(Style)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: right;\n\n  > div {\n    justify-content: flex-end;\n\n    > span {\n      margin-right: 5px;\n      margin-left: 0;\n    }\n  }\n"], ["\n  text-align: right;\n\n  > div {\n    justify-content: flex-end;\n\n    > span {\n      margin-right: 5px;\n      margin-left: 0;\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3;
