export var HistoryLogChangeType;
(function (HistoryLogChangeType) {
    HistoryLogChangeType["Update"] = "Update";
    HistoryLogChangeType["Create"] = "Create";
    HistoryLogChangeType["Delete"] = "Delete";
    HistoryLogChangeType["AddToSet"] = "AddToSet";
    HistoryLogChangeType["RemoveFromSet"] = "RemoveFromSet";
    HistoryLogChangeType["Copied"] = "Copied";
})(HistoryLogChangeType || (HistoryLogChangeType = {}));
export var CommonHistoryObjectType;
(function (CommonHistoryObjectType) {
    CommonHistoryObjectType["Property"] = "Property";
})(CommonHistoryObjectType || (CommonHistoryObjectType = {}));
