var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext } from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeType } from './models';
import { useThemeDetector } from './useThemeDetector';
import { useThemeMode } from './useThemeMode';
export var ThemeContext = createContext({});
export var ThemeProvider = function (_a) {
    var _b, _c;
    var themes = _a.themes, children = _a.children;
    var isUsingDarkTheme = useThemeDetector();
    var defaultTheme = (_b = themes.find(function (theme) {
        return (theme.type === (isUsingDarkTheme ? ThemeType.Dark : ThemeType.Light));
    })) !== null && _b !== void 0 ? _b : themes[0];
    var themeNames = themes.map(function (theme) { return theme.name; });
    var _d = useThemeMode(defaultTheme.name, themeNames), themeName = _d[0], switchTheme = _d[1];
    var theme = (_c = themes.find(function (theme) {
        return theme.name === themeName;
    })) !== null && _c !== void 0 ? _c : defaultTheme;
    return (_jsx(ThemeContext.Provider, __assign({ value: {
            themeName: themeName,
            switchTheme: switchTheme,
        } }, { children: _jsx(StyledThemeProvider, __assign({ theme: theme }, { children: children })) })));
};
