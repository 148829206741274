var _a;
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { config } from '../config';
var browserHistory = createBrowserHistory();
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        disableTelemetry: config.appInsights.disabled,
        instrumentationKey: config.appInsights.key,
        extensions: [reactPlugin],
        extensionConfig: (_a = {},
            _a[reactPlugin.identifier] = { history: browserHistory },
            _a),
    },
});
appInsights.addTelemetryInitializer(function (item) {
    var _a, _b, _c;
    // Do not log LaunchDarkly
    if ((_a = item.baseData) === null || _a === void 0 ? void 0 : _a.target.includes('launchdarkly.com')) {
        return false;
    }
    // Do not log /user|auth/refresh
    if ((_b = item.baseData) === null || _b === void 0 ? void 0 : _b.target.endsWith('/refresh')) {
        return false;
    }
    // Do not log /messages/my
    if ((_c = item.baseData) === null || _c === void 0 ? void 0 : _c.target.endsWith('/messages/my')) {
        return false;
    }
    return true;
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };
