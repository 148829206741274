import { Breakpoints } from '@dimatech/shared/lib/themes';
import { Container, ContainerWrapper } from 'components/Container';
import { PoliciesBox } from 'components/PoliciesBox';
import { View } from 'components/View';
import { ReactNode } from 'react';
import styled from 'styled-components';

export const PoliciesView = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  return (
    <View>
      <PoliciesBox />

      <Info>
        <Container>{children}</Container>
      </Info>
    </View>
  );
};

const Info = styled(ContainerWrapper)`
  > div {
    justify-content: center;
  }

  > div > div {
    max-width: ${() => Breakpoints.medium};
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  li {
    padding-bottom: 10px;
  }
`;
