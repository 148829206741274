var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useId } from 'react';
import { BsQuestion } from 'react-icons/bs';
import styled from 'styled-components';
import { BadgeMini, BadgeTiny } from './Badge';
import { TooltipContent } from './tooltip';
export var HelpIcon = function (_a) {
    var title = _a.title, text = _a.text, showTiny = _a.showTiny, _b = _a.isInverted, isInverted = _b === void 0 ? false : _b, style = _a.style, icon = _a.icon;
    var id = useId();
    return text ? (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: "tt_help_".concat(id), title: title, text: text }), showTiny ? (_jsx(BadgeTiny, __assign({ style: style, "data-tip": true, "data-for": "tt_help_".concat(id), "$inverted": isInverted }, { children: icon !== null && icon !== void 0 ? icon : _jsx(BsQuestion, {}) }))) : (_jsx(BadgeMini, __assign({ style: style, "data-tip": true, "data-for": "tt_help_".concat(id), "$inverted": isInverted }, { children: icon !== null && icon !== void 0 ? icon : _jsx(BsQuestion, {}) })))] })) : null;
};
HelpIcon.displayName = 'HelpIcon';
export var HelpIconLeft = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n"])));
HelpIconLeft.displayName = 'HelpIconLeft';
export var HelpIconCentered = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
HelpIconCentered.displayName = 'HelpIconCentered';
var templateObject_1, templateObject_2;
