var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { BsSearch } from 'react-icons/bs';
import styled from 'styled-components';
import { Input } from './Input';
export var InputSearch = function (props) {
    var t = useTranslation().t;
    var containerStyle = props.containerStyle, handleButtonClick = props.handleButtonClick, placeholder = props.placeholder, rest = __rest(props, ["containerStyle", "handleButtonClick", "placeholder"]);
    return (_jsxs(Style, __assign({ style: containerStyle }, { children: [_jsx(Input, __assign({}, rest, { placeholder: placeholder !== null && placeholder !== void 0 ? placeholder : t('Common.UI.Search') })), _jsx(BsSearch, { style: { cursor: handleButtonClick ? 'pointer' : 'default' }, onClick: handleButtonClick })] })));
};
InputSearch.displayName = 'InputSearch';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex-wrap: nowrap;\n\n  input {\n    padding-right: 25px;\n  }\n\n  > svg {\n    margin-left: -20px;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  flex-wrap: nowrap;\n\n  input {\n    padding-right: 25px;\n  }\n\n  > svg {\n    margin-left: -20px;\n  }\n"])));
var templateObject_1;
