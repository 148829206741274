import { jsx as _jsx } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { LocalizationContext } from '../../localization';
import { DatePickerContainer, DatePickerStyle } from './DateTimePicker';
export var DatePicker = function (_a) {
    var date = _a.date, setDate = _a.setDate, min = _a.min, name = _a.name, inverted = _a.inverted, readonly = _a.readonly;
    var locale = useContext(LocalizationContext).locale;
    var _b = useState(date ? new Date(date) : null), selectedDate = _b[0], setSelectedDate = _b[1];
    var handleChange = function (date) {
        setSelectedDate(date);
        setDate(date);
    };
    useEffect(function () {
        setSelectedDate(date ? new Date(date) : null);
    }, [date]);
    return (_jsx(DatePickerContainer, { children: _jsx(DatePickerStyle, { inverted: inverted, name: name, selected: selectedDate, onChange: function (date) { return handleChange(date); }, locale: locale, minDate: min, showWeekNumbers: true, dateFormat: 'P', readOnly: readonly }) }));
};
DatePicker.displayName = 'DatePicker';
