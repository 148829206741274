var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
export var LoaderEllipsis = function (_a) {
    var children = _a.children, className = _a.className, style = _a.style;
    return (_jsx(Container, { children: _jsxs(Style, __assign({ style: style, className: className }, { children: [children, _jsxs("span", __assign({ className: "ellipsis" }, { children: [_jsx("span", { children: "." }), _jsx("span", { children: "." }), _jsx("span", { children: "." })] }))] })) }));
};
LoaderEllipsis.displayName = 'LoaderEllipsis';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
var Style = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  z-index: 110;\n\n  .ellipsis span {\n    opacity: 0;\n    animation: part 1s infinite;\n    font-size: 1.5em;\n  }\n\n  .ellipsis span:nth-child(1) {\n    animation-delay: 0s;\n  }\n  .ellipsis span:nth-child(2) {\n    animation-delay: 0.1s;\n  }\n  .ellipsis span:nth-child(3) {\n    animation-delay: 0.2s;\n  }\n\n  @keyframes part {\n    0% {\n      opacity: 0;\n    }\n    50% {\n      opacity: 1;\n    }\n    100% {\n      opacity: 0;\n    }\n  }\n"], ["\n  z-index: 110;\n\n  .ellipsis span {\n    opacity: 0;\n    animation: part 1s infinite;\n    font-size: 1.5em;\n  }\n\n  .ellipsis span:nth-child(1) {\n    animation-delay: 0s;\n  }\n  .ellipsis span:nth-child(2) {\n    animation-delay: 0.1s;\n  }\n  .ellipsis span:nth-child(3) {\n    animation-delay: 0.2s;\n  }\n\n  @keyframes part {\n    0% {\n      opacity: 0;\n    }\n    50% {\n      opacity: 1;\n    }\n    100% {\n      opacity: 0;\n    }\n  }\n"])));
var templateObject_1, templateObject_2;
