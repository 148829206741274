import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import img_icon_chevron_down from 'assets/images/icon-chevron-down.svg';
import { useState } from 'react';
import styled from 'styled-components';

export const Question = ({
  title,
  content,
}: {
  title: string;
  content: string;
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Style>
      <div onClick={() => setIsOpen(!isOpen)}>
        <div>
          <img
            src={img_icon_chevron_down}
            style={isOpen ? { transform: 'rotate(180deg)' } : undefined}
            alt={isOpen ? 'Arrow up' : 'Arrow down'}
          />
        </div>

        <div>{title}</div>
      </div>

      {isOpen && <div dangerouslySetInnerHTML={{ __html: content }} />}
    </Style>
  );
};

const Style = styled.div`
  padding-bottom: 20px;

  > div:last-of-type {
    margin-left: 27px;
  }

  > div:first-of-type {
    display: flex;
    flex-direction: row;
    gap: 10px;

    margin-left: 0;
    cursor: pointer;

    //font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};
    font-weight: 400;

    @media (min-width: ${Breakpoints.medium}) {
      font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.l};
    }
  }
`;
