var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import i18next from 'i18next';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { withTheme } from 'styled-components';
import { getDecimalSeparator, getThousandSeparator } from '../../utils';
import { Input } from './Input';
export var InputNumber = withTheme(function (props) {
    var decimals = props.decimals, 
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    type = props.type, maxValue = props.maxValue, onValueChange = props.onValueChange, onFieldBlur = props.onFieldBlur, invalid = props.invalid, disabled = props.disabled, theme = props.theme, style = props.style, rest = __rest(props, ["decimals", "type", "maxValue", "onValueChange", "onFieldBlur", "invalid", "disabled", "theme", "style"]);
    var maxAllowedValue = maxValue !== null && maxValue !== void 0 ? maxValue : Number.MAX_SAFE_INTEGER;
    var _a = useState(), currentValues = _a[0], setCurrentValues = _a[1];
    return (_jsx(NumericFormat, __assign({ onValueChange: function (values) {
            setCurrentValues(values);
            onValueChange && onValueChange(values);
        }, onBlur: function () {
            onFieldBlur && currentValues && onFieldBlur(currentValues);
        }, isAllowed: function (values) {
            var floatValue = values.floatValue;
            if (floatValue === undefined) {
                return true;
            }
            return floatValue < maxAllowedValue;
        }, customInput: Input, decimalScale: decimals, decimalSeparator: getDecimalSeparator(i18next.language), thousandSeparator: getThousandSeparator(i18next.language), valueIsNumericString: true, style: __assign({ textAlign: 'right', borderBottomColor: invalid
                ? theme.colors.error
                : disabled
                    ? theme.colors.dim
                    : theme.colors.secondary, color: disabled ? theme.colors.dim : theme.colors.onBackground }, style), disabled: disabled }, rest)));
});
InputNumber.displayName = 'InputNumber';
