var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// Enum values that can be used in common filters and settings
export var FilterableEntityType;
(function (FilterableEntityType) {
    FilterableEntityType["Region"] = "Region";
    FilterableEntityType["Municipality"] = "Municipality";
    FilterableEntityType["Government"] = "Government";
    FilterableEntityType["Other"] = "Other";
})(FilterableEntityType || (FilterableEntityType = {}));
// Enum values that are mainly for entities and not on customers
// level and that should not be used in filters
export var ExtendedEntityType;
(function (ExtendedEntityType) {
    ExtendedEntityType["Poo"] = "Poo";
    ExtendedEntityType["RealEstate"] = "RealEstate";
    ExtendedEntityType["HealthAndSocialCare"] = "HealthAndSocialCare";
    ExtendedEntityType["Energy"] = "Energy";
    ExtendedEntityType["Infrastructure"] = "Infrastructure";
    ExtendedEntityType["OtherPoo"] = "OtherPoo";
})(ExtendedEntityType || (ExtendedEntityType = {}));
export var EntityType = __assign(__assign({}, FilterableEntityType), ExtendedEntityType);
