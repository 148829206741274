var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from 'styled-components';
export var SortElements = function (_a) {
    var children = _a.children;
    return _jsx(Style, { children: children === null || children === void 0 ? void 0 : children.map(function (child) { return child; }) });
};
SortElements.displayName = 'SortElements';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-flex;\n  justify-content: space-between;\n\n  > div {\n    margin-left: 10px;\n  }\n\n  > div:first-of-type {\n    margin-left: 0;\n  }\n"], ["\n  display: inline-flex;\n  justify-content: space-between;\n\n  > div {\n    margin-left: 10px;\n  }\n\n  > div:first-of-type {\n    margin-left: 0;\n  }\n"])));
var templateObject_1;
