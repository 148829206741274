import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import styled from 'styled-components';

export const Container = styled.div<{
  readonly wrapReverse?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-wrap: ${(props) => (props.wrapReverse ? 'wrap-reverse' : 'wrap')};

  width: 100%;
  max-width: ${({ theme }: { theme: Theme }) => theme.size.viewMaxWidth};

  @media (min-width: ${Breakpoints.medium}) {
    flex-wrap: nowrap;
  }
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  > div > div {
    margin: 0 30px;
  }
`;

export const ContainerWrapperGrid = styled(ContainerWrapper)`
  h2 {
    margin-bottom: 30px;
  }

  > div > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    margin: 0;
    padding: 0 10px;

    > * {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 100%;
    }
  }

  @media (min-width: ${Breakpoints.small}) {
    > div > div {
      flex-wrap: nowrap;
    }
  }
`;

export const ContainerWrapperHighlight = styled(ContainerWrapper)`
  > div > div {
    color: ${({ theme }: { theme: Theme }) => theme.colors.onSurfaceVariant};
    background: linear-gradient(
      ${({ theme }: { theme: Theme }) =>
        `90deg, ${theme.colors.primaryVariant} 0%, ${theme.colors.primary} 100%`}
    );

    padding: 10px;
    width: 100%;

    @media (min-width: ${Breakpoints.medium}) {
      padding: 20px;
    }
  }
`;

export const ContainerWrapperGridHighlight = styled(ContainerWrapperGrid)<{
  readonly color?: string;
}>`
  > div {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    color: ${({ theme }: { theme: Theme }) => theme.colors.onPrimary};
    background-color: ${(props) => props.color ?? props.theme.colors.primary};
  }

  > div > div {
    background: none;
  }
`;
