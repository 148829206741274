var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
export var Label = styled.label(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 10px;\n  display: block;\n  display: flex;\n\n  ", "\n"], ["\n  margin-top: 10px;\n  display: block;\n  display: flex;\n\n  ", "\n"])), function (props) {
    return (props.disabled || props.readOnly) && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      color: ", ";\n    "], ["\n      color: ", ";\n    "])), props.theme.colors.dim);
});
Label.displayName = 'Label';
var templateObject_1, templateObject_2;
