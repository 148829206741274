var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Td = styled.td(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  vertical-align: top;\n  padding: 5px 10px;\n\n  &.narrow {\n    padding: 5px 5px;\n  }\n\n  border-bottom: 1px solid\n    ", ";\n  border-top: 1px solid ", ";\n\n  > svg {\n    font-size: ", ";\n    vertical-align: middle;\n  }\n"], ["\n  vertical-align: top;\n  padding: 5px 10px;\n\n  &.narrow {\n    padding: 5px 5px;\n  }\n\n  border-bottom: 1px solid\n    ", ";\n  border-top: 1px solid ", ";\n\n  > svg {\n    font-size: ", ";\n    vertical-align: middle;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.border;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.border;
}, function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.xl;
});
Td.displayName = 'Td';
export var TdCentered = styled(Td)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
TdCentered.displayName = 'TdCentered';
export var TdRight = styled(Td)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: right;\n"], ["\n  text-align: right;\n"])));
TdRight.displayName = 'TdRight';
var templateObject_1, templateObject_2, templateObject_3;
