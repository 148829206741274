var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import ReactTooltip from 'react-tooltip';
import styled, { css } from 'styled-components';
import { Breakpoints } from '../../themes';
var style = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block !important;\n  max-width: 500px;\n  padding: 10px;\n  text-align: left;\n\n  color: ", " !important;\n  background-color: ", " !important;\n  border-radius: 3px;\n\n  opacity: 0.9 !important;\n\n  font-size: ", " !important;\n  font-weight: normal;\n\n  .tooltip-title {\n    font-weight: bold;\n    padding-bottom: 3px;\n    margin-bottom: 8px;\n    text-align: left;\n\n    border-bottom: 1px solid\n      ", ";\n  }\n\n  .tooltip-subtitle {\n    font-weight: bold;\n    padding-top: 8px;\n    text-align: left;\n  }\n\n  .tooltip-info {\n    font-style: italic;\n    padding-top: 0;\n    text-align: left;\n  }\n\n  @media screen and (max-width: ", ") {\n    left: 40% !important;\n  }\n"], ["\n  display: inline-block !important;\n  max-width: 500px;\n  padding: 10px;\n  text-align: left;\n\n  color: ", " !important;\n  background-color: ", " !important;\n  border-radius: 3px;\n\n  opacity: 0.9 !important;\n\n  font-size: ", " !important;\n  font-weight: normal;\n\n  .tooltip-title {\n    font-weight: bold;\n    padding-bottom: 3px;\n    margin-bottom: 8px;\n    text-align: left;\n\n    border-bottom: 1px solid\n      ", ";\n  }\n\n  .tooltip-subtitle {\n    font-weight: bold;\n    padding-top: 8px;\n    text-align: left;\n  }\n\n  .tooltip-info {\n    font-style: italic;\n    padding-top: 0;\n    text-align: left;\n  }\n\n  @media screen and (max-width: ", ") {\n    left: 40% !important;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.onBackground;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.s;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
}, Breakpoints.small);
export var Tooltip = styled(ReactTooltip)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), style);
Tooltip.displayName = 'Tooltip';
export var TooltipStyle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), style);
TooltipStyle.displayName = 'TooltipStyle';
var templateObject_1, templateObject_2, templateObject_3;
