import { css } from 'styled-components';

export const generalStyling = css`
  body {
    // Force scrollbar to prevent layout shift when navigating between pages
    overflow-y: scroll;
  }

  h2 {
    margin-bottom: 15px;
  }

  li {
    margin: 15px 0;
  }
`;
