var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var ServiceError = /** @class */ (function (_super) {
    __extends(ServiceError, _super);
    function ServiceError() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return ServiceError;
}(Error));
var NotFoundError = /** @class */ (function (_super) {
    __extends(NotFoundError, _super);
    function NotFoundError(error) {
        var _this = _super.call(this, error.message) || this;
        _this.name = 'NotFoundError';
        _this.innerError = error;
        return _this;
    }
    return NotFoundError;
}(ServiceError));
export { NotFoundError };
var ServiceUnavailableError = /** @class */ (function (_super) {
    __extends(ServiceUnavailableError, _super);
    function ServiceUnavailableError(error) {
        var _this = _super.call(this, 'ServiceUnavailableError') || this;
        _this.name = 'ServiceUnavailableError';
        _this.innerError = error;
        return _this;
    }
    return ServiceUnavailableError;
}(ServiceError));
export { ServiceUnavailableError };
var GenericServiceError = /** @class */ (function (_super) {
    __extends(GenericServiceError, _super);
    function GenericServiceError(error) {
        var _this = _super.call(this, error.message) || this;
        _this.name = 'GenericServiceError';
        _this.innerError = error;
        return _this;
    }
    return GenericServiceError;
}(ServiceError));
export { GenericServiceError };
var UnauthorizedError = /** @class */ (function (_super) {
    __extends(UnauthorizedError, _super);
    function UnauthorizedError(error) {
        var _this = _super.call(this, error.message) || this;
        _this.name = 'UnauthorizedError';
        _this.innerError = error;
        return _this;
    }
    return UnauthorizedError;
}(ServiceError));
export { UnauthorizedError };
var ForbiddenError = /** @class */ (function (_super) {
    __extends(ForbiddenError, _super);
    function ForbiddenError(error) {
        var _this = _super.call(this, error.message) || this;
        _this.name = 'ForbiddenError';
        _this.innerError = error;
        return _this;
    }
    return ForbiddenError;
}(ServiceError));
export { ForbiddenError };
var BadRequestError = /** @class */ (function (_super) {
    __extends(BadRequestError, _super);
    function BadRequestError(error, response) {
        var _this = _super.call(this, error.message) || this;
        _this.name = (response === null || response === void 0 ? void 0 : response.title) || 'BadRequestError';
        _this.innerError = error;
        return _this;
    }
    return BadRequestError;
}(ServiceError));
export { BadRequestError };
var UnhandledError = /** @class */ (function (_super) {
    __extends(UnhandledError, _super);
    function UnhandledError(error) {
        var _this = _super.call(this, error.message) || this;
        _this.name = 'UnhandledError';
        _this.innerError = error;
        return _this;
    }
    return UnhandledError;
}(ServiceError));
export { UnhandledError };
var ValidationError = /** @class */ (function (_super) {
    __extends(ValidationError, _super);
    function ValidationError(error, response) {
        var _this = _super.call(this) || this;
        _this.checkValidationErrors = function (response) {
            var errors = response.errors;
            return Object.keys(errors !== null && errors !== void 0 ? errors : {}).join(',');
        };
        _this.message = _this.checkValidationErrors(response) || error.message;
        _this.name = 'ValidationError';
        _this.innerError = error;
        return _this;
    }
    return ValidationError;
}(ServiceError));
export { ValidationError };
