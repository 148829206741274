var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var ComboBoxList = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  z-index: 100;\n\n  max-height: 300px;\n  overflow-y: auto;\n\n  min-width: 100px;\n  margin-left: 0;\n  box-shadow: ", ";\n"], ["\n  position: absolute;\n  z-index: 100;\n\n  max-height: 300px;\n  overflow-y: auto;\n\n  min-width: 100px;\n  margin-left: 0;\n  box-shadow: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.boxShadows.default;
});
export var ComboBoxListItem = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  z-index: 105;\n  cursor: pointer;\n  width: 100%;\n  padding: 10px;\n  list-style: none;\n\n  background-color: ", ";\n  color: ", ";\n"], ["\n  z-index: 105;\n  cursor: pointer;\n  width: 100%;\n  padding: 10px;\n  list-style: none;\n\n  background-color: ", ";\n  color: ", ";\n"])), function (props) {
    return props.$highlighted
        ? props.theme.colors.secondary
        : props.theme.colors.surface;
}, function (props) {
    return props.$highlighted
        ? props.theme.colors.onSecondary
        : props.theme.colors.onSurface;
});
var templateObject_1, templateObject_2;
