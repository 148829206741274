import { Breakpoints } from '@dimatech/shared/lib/themes';
import { Header } from 'components/Header';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { Footer } from './Footer';

export const View = ({ children }: { children: ReactNode }): JSX.Element => {
  return (
    <Wrapper>
      <Header />

      {children}

      <Footer />
    </Wrapper>
  );
};

View.displayName = 'View';

const Wrapper = styled.div`
  > div {
    margin-bottom: 60px;
  }

  > div:first-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${Breakpoints.large}) {
    > div {
      margin-bottom: 90px;
    }

    > div:first-of-type {
      margin-bottom: 0;
    }
  }
`;
