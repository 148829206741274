import { Breakpoints, Color, Theme } from '@dimatech/shared/lib/themes';
import styled from 'styled-components';

export const HeadingTagLine = styled.div`
  font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};
  font-weight: 100;

  color: ${() => Color.Grey40};

  padding-bottom: 10px;

  @media (min-width: ${Breakpoints.medium}) {
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.base};
  }
`;
