var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useId, useState } from 'react';
import { withTheme } from 'styled-components';
import { BadgeMini } from '../Badge';
import { ButtonLink } from '../form';
import { TooltipContent } from './TooltipContent';
export var LinkWithTooltip = withTheme(function (_a) {
    var theme = _a.theme, title = _a.title, tooltipTitle = _a.tooltipTitle, tooltip = _a.tooltip, icon = _a.icon, handleClick = _a.handleClick, _b = _a.isInverted, isInverted = _b === void 0 ? false : _b, _c = _a.isPrimary, isPrimary = _c === void 0 ? false : _c, _d = _a.isDisabled, isDisabled = _d === void 0 ? false : _d, _e = _a.disabledTimerInMs, disabledTimerInMs = _e === void 0 ? 1000 : _e, style = _a.style, children = _a.children;
    var id = useId();
    var _f = useState(false), isProcessing = _f[0], setIsProcessing = _f[1];
    useEffect(function () {
        if (!disabledTimerInMs || !isProcessing) {
            return;
        }
        var timer = setTimeout(function () {
            setIsProcessing(false);
            clearTimeout(timer);
        }, disabledTimerInMs);
        return function () {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [isProcessing]);
    return (_jsxs("div", __assign({ style: __assign(__assign({}, style), { display: 'flex' }) }, { children: [_jsx(TooltipContent, { id: id, title: tooltipTitle, text: tooltip }), icon && (_jsx(BadgeMini, __assign({ "$inverted": isInverted, "data-tip": true, "data-for": id, disabled: isDisabled || isProcessing, onClick: function (e) {
                    if (isDisabled) {
                        return;
                    }
                    handleClick(e);
                    setIsProcessing(true);
                }, style: isPrimary
                    ? {
                        backgroundColor: theme.colors.secondary,
                        color: theme.colors.onSecondary,
                    }
                    : undefined }, { children: icon }))), (title || children) && (_jsx(ButtonLink, __assign({ "data-tip": true, "data-for": id, disabled: isDisabled || isProcessing, style: { marginLeft: 5 }, onClick: function (e) {
                    handleClick(e);
                    setIsProcessing(true);
                } }, { children: title !== null && title !== void 0 ? title : children })))] })));
});
LinkWithTooltip.displayName = 'LinkWithTooltip';
