var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { isBoolean } from './string';
/**
 * Returns subdomain from a url, i.e. "sub" from "https://sub.domain.com"
 * @param url
 * @returns subdomain or null
 */
export var getSubdomain = function (url) {
    var baseUrl = url === null || url === void 0 ? void 0 : url.replace(/(https?:\/\/)?(www.)?/i, '');
    var parts = baseUrl === null || baseUrl === void 0 ? void 0 : baseUrl.split('.');
    return (parts === null || parts === void 0 ? void 0 : parts.length) > 1 && parts[0] && isNaN(+parts[0]) ? parts[0] : null;
};
/**
 * Updates current url. Use when you want to update the current url
 * without navigating or reloading the page.
 * @param url
 */
export var updateCurrentUrl = function (url) {
    window.history.replaceState(null, '', url);
};
/**
 * Converts a query string to a json object
 * @param query
 * @returns json object
 */
export var queryStringToJson = function (query
// eslint-disable-next-line @typescript-eslint/no-explicit-any
) {
    var pairs = (query.startsWith('?') ? query.slice(1) : query).split('&');
    return pairs.reduce(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function (acc, pair) {
        var _a, _b;
        var _c = pair.split('='), key = _c[0], value = _c[1];
        var current = acc[key];
        var numeric = Number(value);
        var newValue = numeric
            ? numeric.valueOf()
            : isBoolean(value)
                ? value === 'true'
                : value;
        if (current) {
            return __assign(__assign({}, acc), (_a = {}, _a[key] = Array.isArray(current)
                ? __spreadArray(__spreadArray([], current, true), [newValue], false) : [current, newValue], _a));
        }
        else {
            return __assign(__assign({}, acc), (_b = {}, _b[key] = newValue, _b));
        }
    }, {});
};
/**
 * Converts a json object to a query string, only supports shallow objects
 * and will not successfully convert nested objects.
 * @param json
 * @returns query string
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export var jsonToQueryString = function (json) {
    return Object.keys(json)
        .map(function (key) {
        var value = json[key];
        if (Array.isArray(value)) {
            return value.map(function (val) { return "".concat(key, "=").concat(val); }).join('&');
        }
        else if (isBoolean(value) || value) {
            return "".concat(key, "=").concat(value);
        }
    })
        .filter(function (value) { return value; })
        .join('&');
};
