import { Theme } from '@dimatech/shared/lib/themes';
import { Container, ContainerWrapperHighlight } from 'components/Container';
import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

export const HeroBox = ({
  style,
  children,
}: {
  style?: CSSProperties;
  children: ReactNode;
}): JSX.Element => {
  return (
    <Intro>
      <Container>
        <div style={style}>{children}</div>
      </Container>
    </Intro>
  );
};

const Intro = styled(ContainerWrapperHighlight)`
  margin-top: 0;

  > div > div {
    background: none;
    background-color: ${({ theme }: { theme: Theme }) => theme.colors.primary};

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 60px 20px;
  }
`;
