var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useId } from 'react';
import styled from 'styled-components';
import { TooltipContent } from './tooltip';
export var Badge = function (props) {
    var tooltip = props.tooltip, tooltipTitle = props.tooltipTitle, rest = __rest(props, ["tooltip", "tooltipTitle"]);
    var id = useId();
    if (tooltip || tooltipTitle) {
        return (_jsxs(_Fragment, { children: [_jsx(TooltipContent, { id: id, title: tooltipTitle, text: tooltip }), _jsx(BadgeStyle, __assign({}, rest, { "data-tip": true, "data-for": id }))] }));
    }
    return _jsx(BadgeStyle, __assign({}, rest));
};
var BadgeStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 40px;\n  width: 40px;\n  padding: 0 !important;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-shrink: 0;\n\n  background-color: ", ";\n  color: ", ";\n\n  box-shadow: ", ";\n\n  border-radius: 50%;\n"], ["\n  height: 40px;\n  width: 40px;\n  padding: 0 !important;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-shrink: 0;\n\n  background-color: ", ";\n  color: ", ";\n\n  box-shadow: ", ";\n\n  border-radius: 50%;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.primary;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onPrimary;
}, function (_a) {
    var theme = _a.theme;
    return theme.boxShadows.default;
});
export var BadgeSmall = styled(Badge)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 30px;\n  width: 30px;\n\n  ", "\n\n  ", "\n\n  ", "\n"], ["\n  height: 30px;\n  width: 30px;\n\n  ", "\n\n  ", "\n\n  ", "\n"])), function (props) {
    return props.$inverted &&
        "\n      color: ".concat(props.disabled
            ? props.theme.colors.onSurface
            : props.theme.colors.onSurface, ";\n      background-color: ").concat(props.disabled ? props.theme.colors.dim : props.theme.colors.surface, ";\n    ");
}, function (props) {
    return !props.$inverted &&
        "\n      color: ".concat(props.disabled
            ? props.theme.colors.onBackground
            : props.theme.colors.onBackground, ";\n      background-color: ").concat(props.disabled
            ? props.theme.colors.background
            : props.theme.colors.background, ";\n    ");
}, function (props) {
    return !props.disabled &&
        "\n      cursor: pointer;\n\n      &:hover {\n        background-color: ".concat(props.$inverted
            ? props.theme.colors.onBackground
            : props.theme.colors.onSurface, " !important;\n        color: ").concat(props.$inverted
            ? props.theme.colors.background
            : props.theme.colors.surface, " !important;\n      }\n    ");
});
export var BadgeMini = styled(BadgeSmall)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 25px;\n  width: 25px;\n  font-size: ", ";\n"], ["\n  height: 25px;\n  width: 25px;\n  font-size: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.base;
});
export var BadgeTiny = styled(BadgeSmall)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 18px;\n  width: 18px;\n  font-size: ", ";\n"], ["\n  height: 18px;\n  width: 18px;\n  font-size: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.base;
});
export var BadgeLarge = styled(BadgeSmall)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 50px;\n  width: 50px;\n  font-size: ", ";\n"], ["\n  height: 50px;\n  width: 50px;\n  font-size: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.xl;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
