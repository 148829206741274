var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
export var Loader = function (_a) {
    var children = _a.children, style = _a.style;
    return (_jsxs(Container, { children: [_jsx(LoaderStyle, { style: style }), children && _jsx(LoaderContent, { children: children })] }));
};
Loader.displayName = 'Loader';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
export var LoaderStyle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  z-index: 110;\n  width: 30px;\n  height: 30px;\n\n  border: 5px solid ", ";\n  border-top: 5px solid\n    ", ";\n  border-radius: 50%;\n\n  animation: spin 1.3s linear infinite;\n\n  @keyframes spin {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n"], ["\n  z-index: 110;\n  width: 30px;\n  height: 30px;\n\n  border: 5px solid ", ";\n  border-top: 5px solid\n    ", ";\n  border-radius: 50%;\n\n  animation: spin 1.3s linear infinite;\n\n  @keyframes spin {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.secondary;
});
export var LoaderContent = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  opacity: 0.5;\n  margin-left: 10px;\n"], ["\n  opacity: 0.5;\n  margin-left: 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3;
