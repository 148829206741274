var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { css } from 'styled-components';
export var Tr = function (_a) {
    var onSelect = _a.onSelect, isSelected = _a.isSelected, _b = _a.isHoverable, isHoverable = _b === void 0 ? false : _b, _c = _a.isExpandable, isExpandable = _c === void 0 ? false : _c, _d = _a.isExpanded, isExpanded = _d === void 0 ? false : _d, children = _a.children, style = _a.style, className = _a.className;
    var handleSelect = function (e) {
        onSelect && onSelect(e);
    };
    return onSelect ? (_jsx(Style, __assign({ style: style, onClick: function (e) { return handleSelect(e); }, "$selected": isSelected, "$selectable": !!onSelect, "$expanded": isExpanded, "$expandable": isExpandable, className: isHoverable
            ? className
                ? "isHoverable ".concat(className)
                : 'isHoverable'
            : className }, { children: children }))) : (_jsx(Style, __assign({ style: style, "$selected": isSelected, className: isHoverable
            ? className
                ? "isHoverable ".concat(className)
                : 'isHoverable'
            : className }, { children: children })));
};
Tr.displayName = 'Tr';
var Style = styled.tr(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: ", ";\n\n  background-color: ", ";\n  color: ", ";\n\n  &.isHoverable:hover {\n    background-color: ", " !important;\n\n    color: ", " !important;\n  }\n\n  ", "\n"], ["\n  cursor: ", ";\n\n  background-color: ", ";\n  color: ", ";\n\n  &.isHoverable:hover {\n    background-color: ", " !important;\n\n    color: ", " !important;\n  }\n\n  ", "\n"])), function (props) { return (props.$selectable ? 'pointer' : 'default'); }, function (props) {
    return props.$selected ? "".concat(props.theme.colors.primary, " !important") : 'unset';
}, function (props) {
    return props.$selected ? "".concat(props.theme.colors.onPrimary, " !important") : 'unset';
}, function (props) {
    return props.$selected
        ? props.theme.colors.primary
        : props.theme.colors.secondary;
}, function (props) {
    return props.$selected
        ? props.theme.colors.onPrimary
        : props.theme.colors.onSecondary;
}, function (props) {
    if (props.$expanded) {
        return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        > td:first-child {\n          border-left: 4px solid\n            ", ";\n        }\n      "], ["\n        > td:first-child {\n          border-left: 4px solid\n            ", ";\n        }\n      "])), function (_a) {
            var theme = _a.theme;
            return theme.colors.secondary;
        });
    }
    if (props.$expandable) {
        return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        > td:first-child {\n          border-left: 4px solid transparent;\n        }\n\n        &:hover {\n          > td:first-child {\n            border-left: 4px solid\n              ", ";\n          }\n        }\n      "], ["\n        > td:first-child {\n          border-left: 4px solid transparent;\n        }\n\n        &:hover {\n          > td:first-child {\n            border-left: 4px solid\n              ", ";\n          }\n        }\n      "])), function (_a) {
            var theme = _a.theme;
            return theme.colors.primary;
        });
    }
});
var templateObject_1, templateObject_2, templateObject_3;
