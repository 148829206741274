var _a;
import { Language, Translations } from '../models';
import { formatDate } from '../utils';
import { getLocale } from './useLocale';
export var defaultOptions = {
    fallbackLng: Language.Swedish,
    lng: (_a = getLocale()) !== null && _a !== void 0 ? _a : Language.Swedish,
    debug: false,
    load: 'languageOnly',
    defaultNS: Translations.Common,
    keySeparator: '.',
    interpolation: {
        escapeValue: false,
        format: formatDate,
    },
    react: {
        transKeepBasicHtmlNodesFor: [
            'br',
            'strong',
            'em',
            'i',
            'p',
            'b',
            'ul',
            'ol',
            'li',
        ],
        useSuspense: false,
    },
};
