import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PoliciesView } from './PoliciesView';

export const UserTermsReadMore = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <PoliciesView>
      <div>
        <div>
          <Link to="/policies">Policies och villkor</Link> / Våra villkor
        </div>

        <div>
          <Heading2>{t('Policies.UserTermsReadMore.Title')}</Heading2>
          <p>{t('Policies.UserTermsReadMore.Text')}</p>
          <p>{t('Policies.UserTermsReadMore.Text2')}</p>

          <ul>
            <li>
              <Link to="/user-agreements-dimios">
                {t('Policies.UserTermsReadMore.DownloadDimios')}
              </Link>
            </li>
            <li>
              <Link to="/user-agreements-dios">
                {t('Policies.UserTermsReadMore.DownloadDios')}
              </Link>
            </li>
            <li>
              <Link to="/user-agreements-pios">
                {t('Policies.UserTermsReadMore.DownloadPios')}
              </Link>
            </li>
            <li>
              <Link to="/user-agreements-dikios">
                {t('Policies.UserTermsReadMore.DownloadDikios')}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </PoliciesView>
  );
};
