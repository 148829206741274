var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { Breakpoints } from '../../themes';
export var Table = styled.table(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  border-spacing: 0;\n  border-collapse: collapse;\n\n  ", "\n"], ["\n  width: 100%;\n  border-spacing: 0;\n  border-collapse: collapse;\n\n  ", "\n"])), function (props) {
    if (props.$striped) {
        return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        tr:nth-child(even) {\n          background-color: ", ";\n        }\n      "], ["\n        tr:nth-child(even) {\n          background-color: ", ";\n        }\n      "])), function (_a) {
            var theme = _a.theme;
            return theme.colors.background;
        });
    }
});
Table.displayName = 'Table';
export var TableResponsiveContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  @media screen and (max-width: ", ") {\n    overflow: auto;\n    width: 80vw;\n  }\n"], ["\n  @media screen and (max-width: ", ") {\n    overflow: auto;\n    width: 80vw;\n  }\n"])), Breakpoints.small);
TableResponsiveContainer.displayName = 'TableResponsiveContainer';
var templateObject_1, templateObject_2, templateObject_3;
