import { Heading3 } from '@dimatech/shared/lib/components/typography';
import { Breakpoints } from '@dimatech/shared/lib/themes';
import { ReactNode } from 'react';
import styled from 'styled-components';

export const InfoBox = ({
  image,
  title,
  children,
}: {
  image: string;
  title: string;
  children: ReactNode;
}): JSX.Element => {
  return (
    <Style>
      <img src={image} alt="icon" />

      <div>
        <Heading3>{title}</Heading3>
        {children}
      </div>
    </Style>
  );
};

const Style = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  margin-bottom: 20px;

  img {
    margin-bottom: 10px;

    height: 60px;
    width: 60px;
  }

  @media (min-width: ${Breakpoints.large}) {
    padding: 20px;
    margin-bottom: 0;

    img {
      height: 100px;
      width: 100px;
    }
  }
`;
