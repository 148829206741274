var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useSelect } from 'downshift';
import { useEffect, useState } from 'react';
import { BsCaretDownFill, BsCaretUpFill } from 'react-icons/bs';
import styled from 'styled-components';
import { LoaderSmall } from '../loader';
import { formField } from './css';
import { Label } from './Label';
export var Autocomplete = function (_a) {
    var label = _a.label, items = _a.items, selected = _a.selectedItem, isLoading = _a.isLoading, handleChange = _a.handleChange, renderItem = _a.renderItem, renderSelectedItem = _a.renderSelectedItem, style = _a.style, reset = _a.reset, placeholder = _a.placeholder;
    var _b = useState(selected !== null && selected !== void 0 ? selected : null), selectedItem = _b[0], setSelectedItem = _b[1];
    var _c = useSelect({
        items: items !== null && items !== void 0 ? items : [],
        selectedItem: selectedItem,
        onSelectedItemChange: function (changes) {
            var _a, _b;
            setSelectedItem((_a = changes.selectedItem) !== null && _a !== void 0 ? _a : null);
            handleChange((_b = changes.selectedItem) !== null && _b !== void 0 ? _b : null);
        },
    }), isOpen = _c.isOpen, getMenuProps = _c.getMenuProps, highlightedIndex = _c.highlightedIndex, getToggleButtonProps = _c.getToggleButtonProps, getLabelProps = _c.getLabelProps, getItemProps = _c.getItemProps;
    useEffect(function () {
        if (reset === false) {
            setSelectedItem(selected);
        }
        else {
            setSelectedItem(null);
        }
    }, [reset, selected]);
    return (_jsxs(Style, __assign({ style: style }, { children: [_jsxs("div", { children: [label && _jsx(Label, __assign({}, getLabelProps(), { children: label })), _jsxs(ComboBoxListPlaceHolder, __assign({}, getToggleButtonProps(), { children: [selectedItem === null ? (_jsx("div", { children: placeholder !== null && placeholder !== void 0 ? placeholder : '' })) : (renderSelectedItem(selectedItem)), _jsx("div", { children: isOpen ? (_jsx(BsCaretUpFill, { style: { verticalAlign: 'text-bottom' } })) : (_jsx(BsCaretDownFill, { style: { verticalAlign: 'text-bottom' } })) })] }))] }), _jsxs(ComboBoxList, __assign({}, getMenuProps(), { children: [isOpen && !!isLoading && (_jsx(ComboBoxListItem, { children: _jsx(LoaderSmall, {}) })), isOpen &&
                        (items === null || items === void 0 ? void 0 : items.map(function (item, index) { return (_jsx(ComboBoxListItem, __assign({ "$highlighted": highlightedIndex === index }, getItemProps({ item: item, index: index }), { children: renderItem(item, selectedItem !== null && selectedItem !== void 0 ? selectedItem : null) }), index)); }))] }))] })));
};
Autocomplete.displayName = 'Autocomplete';
export var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 110px;\n\n  ul {\n    margin-left: 0;\n  }\n"], ["\n  width: 110px;\n\n  ul {\n    margin-left: 0;\n  }\n"])));
var ComboBoxListPlaceHolder = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n\n  width: 110px;\n  min-height: 34px;\n  padding: 5px;\n  margin: 5px 0;\n\n  ", ";\n"], ["\n  display: flex;\n  justify-content: space-between;\n\n  width: 110px;\n  min-height: 34px;\n  padding: 5px;\n  margin: 5px 0;\n\n  ", ";\n"])), formField);
var ComboBoxList = styled.ul(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100px;\n  position: absolute;\n  z-index: 100;\n  box-shadow: ", ";\n\n  max-height: 300px;\n  overflow-y: auto;\n  border-radius: none;\n"], ["\n  width: 100px;\n  position: absolute;\n  z-index: 100;\n  box-shadow: ", ";\n\n  max-height: 300px;\n  overflow-y: auto;\n  border-radius: none;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.boxShadows.default;
});
var ComboBoxListItem = styled.li(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n  width: 100%;\n  padding: 10px;\n  list-style: none;\n\n  background-color: ", ";\n\n  color: ", ";\n"], ["\n  cursor: pointer;\n  width: 100%;\n  padding: 10px;\n  list-style: none;\n\n  background-color: ", ";\n\n  color: ", ";\n"])), function (props) {
    return props.$highlighted
        ? props.theme.colors.secondary
        : props.theme.colors.surface;
}, function (props) {
    return props.$highlighted
        ? props.theme.colors.onSecondary
        : props.theme.colors.onSurface;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
