import { Breakpoints } from '@dimatech/shared/lib/themes';
import { Container } from 'components/Container';
import { ReactNode } from 'react';
import styled from 'styled-components';

export const HeroProductIntro = ({
  children,
  color,
}: {
  children?: ReactNode;
  color: string;
}): JSX.Element => {
  return (
    <Style color={color}>
      <Container>{children}</Container>
    </Style>
  );
};

HeroProductIntro.displayName = 'HeroProductIntro';

const Style = styled.div<{
  readonly color: string;
}>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;

  width: 100%;

  padding: 50px 50px 50px 40px;

  background: linear-gradient(270deg, ${(props) => props.color} 0%, #000 100%);

  > div > div > div {
    display: flex;
    flex-direction: column;

    margin: 0 30px;
    gap: 15px;
  }

  @media (min-width: ${Breakpoints.medium}) {
    padding-left: 60px !important;
  }
`;
