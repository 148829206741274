var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import styled from 'styled-components';
export var Alert = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 5px;\n\n  background-color: ", ";\n  color: ", ";\n\n  border-radius: 3px;\n\n  a,\n  button {\n    color: ", ";\n  }\n"], ["\n  padding: 5px;\n\n  background-color: ", ";\n  color: ", ";\n\n  border-radius: 3px;\n\n  a,\n  button {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
});
export var AlertInfo = styled(Alert)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n\n  a,\n  button {\n    color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n\n  a,\n  button {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.info;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onInfo;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onInfo;
});
export var AlertWarning = styled(Alert)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n\n  a,\n  button {\n    color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n\n  a,\n  button {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.warning;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onWarning;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onWarning;
});
export var AlertError = styled(Alert)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n\n  a,\n  button {\n    color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n\n  a,\n  button {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.error;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onError;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onError;
});
export var AlertSuccess = styled(Alert)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n\n  a,\n  button {\n    color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n\n  a,\n  button {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.success;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSuccess;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSuccess;
});
export var AlertLowPrio = styled(Alert)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n\n  a,\n  button {\n    color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n\n  a,\n  button {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.lowPriority;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onLowPriority;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onLowPriority;
});
export var AlertToast = function (_a) {
    var isSaved = _a.isSaved, setIsSaved = _a.setIsSaved, children = _a.children, style = _a.style;
    var _b = useState(false), isChangesSaved = _b[0], setIsChangesSaved = _b[1];
    useEffect(function () {
        if (!isSaved) {
            return;
        }
        setIsChangesSaved(isSaved);
        var timer = setTimeout(function () {
            setIsChangesSaved(false);
            setIsSaved(false);
            clearTimeout(timer);
        }, 2000);
        return function () {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [isSaved]);
    return (_jsx(AlertToastStyle, __assign({ style: style, className: isChangesSaved ? 'fade-in' : 'fade-out' }, { children: children })));
};
var AlertToastStyle = styled(AlertSuccess)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  &.fade-out {\n    opacity: 0;\n    display: none;\n    transition: opacity 1s;\n  }\n  &.fade-in {\n    opacity: 1;\n    display: block;\n    transition: opacity 0.2s;\n  }\n"], ["\n  &.fade-out {\n    opacity: 0;\n    display: none;\n    transition: opacity 1s;\n  }\n  &.fade-in {\n    opacity: 1;\n    display: block;\n    transition: opacity 0.2s;\n  }\n"])));
AlertToast.displayName = 'AlertToast';
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
