import { ScrollToTop } from '@dimatech/shared/lib/components/ScrollToTop';
import { verifyConfiguration } from '@dimatech/shared/lib/config';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import './localization';
import reportWebVitals from './reportWebVitals';

verifyConfiguration();

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);

root.render(
  <Router>
    <App />
    <ScrollToTop scrollToTop={true} />
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
