import { UserAgreementDimios } from '@dimatech/shared/lib/components/user-agreement';
import { Link } from 'react-router-dom';
import { PoliciesView } from './PoliciesView';

export const UserAgreementsDimios = (): JSX.Element => {
  return (
    <PoliciesView>
      <div>
        <div>
          <Link to="/policies">Policies och villkor</Link> /{' '}
          <Link to="/user-terms-read-more">Våra villkor</Link> / Villkor för
          Dimios
        </div>
        <div>
          <UserAgreementDimios />
        </div>
      </div>
    </PoliciesView>
  );
};
