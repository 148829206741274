import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { Container } from 'components/Container';
import { HeadingTagLine } from 'components/HeadingTagLine';
import { Hero } from 'components/Hero';
import { View } from 'components/View';
import { useTranslation } from 'react-i18next';

import img_hero from 'assets/images/partners-hero.jpg';
import { Section } from 'components/Section';

export const Partners = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View>
      <Hero image={img_hero} />

      <Section>
        <Container>
          <HeadingTagLine style={{ justifyContent: 'flex-start' }}>
            {t('Partners.TagLiner')}
          </HeadingTagLine>
        </Container>

        <Container style={{ alignItems: 'flex-start' }}>
          <div style={{ flexGrow: 1, flexBasis: '30%' }}>
            <Heading2>{t('Partners.DigitalAdministration.Title')}</Heading2>
          </div>
          <div style={{ flexGrow: 2, flexBasis: '70%' }}>
            <div>{t('Partners.DigitalAdministration.Text')}</div>
            <p>{t('Partners.DigitalAdministration.Text2')}</p>
            <p>{t('Partners.DigitalAdministration.Text3')}</p>
            <a href="https://www.digitalforvaltning.se/">
              {t('Partners.DigitalAdministration.LinkText')}
            </a>
          </div>
        </Container>

        <Container
          style={{
            alignItems: 'flex-start',
            marginTop: 50,
            marginBottom: 50,
          }}
        >
          <div style={{ flexGrow: 1, flexBasis: '30%' }}>
            <Heading2>{t('Partners.EnJa.Title')}</Heading2>
          </div>
          <div style={{ flexGrow: 2, flexBasis: '70%' }}>
            <div>{t('Partners.EnJa.Text')}</div>
            <p>{t('Partners.EnJa.Text2')}</p>
            <a href="https://enja.se/">{t('Partners.EnJa.LinkText')}</a>
          </div>
        </Container>

        <Container style={{ alignItems: 'flex-start' }}>
          <div style={{ flexGrow: 1, flexBasis: '30%' }}>
            <Heading2>{t('Partners.DigJourney.Title')}</Heading2>
          </div>
          <div style={{ flexGrow: 2, flexBasis: '70%' }}>
            <div>{t('Partners.DigJourney.Text')}</div>
            <p>{t('Partners.DigJourney.Text2')}</p>
            <a href="mailto:joakim@digjourney.com">joakim@digjourney.com</a>
          </div>
        </Container>
      </Section>
    </View>
  );
};

Partners.displayName = 'Partners';
