import { toPng } from 'html-to-image';
import { utils, writeFile } from 'xlsx';
/**
 * Trigger save string (blob) as file via dataUrl
 * @param dataUrl
 * @param fileName
 */
export var saveDataUrlAs = function (dataUrl, fileName) {
    var element = window.document.createElement('a');
    element.href = dataUrl;
    element.download = fileName;
    element.setAttribute('style', 'display:none;');
    (document.body || document.documentElement).appendChild(element);
    if (typeof element.click === 'function') {
        element.click();
    }
    else {
        element.target = '_blank';
        element.dispatchEvent(new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        }));
    }
    URL.revokeObjectURL(element.href);
    element.remove();
};
/**
 * Save html element by id as png
 * See: https://github.com/bubkoo/html-to-image#readme
 * @param elementId
 * @param fileName
 * @returns
 */
export var saveHtmlToPng = function (elementId, fileName) {
    var element = document.getElementById(elementId);
    if (!element) {
        return;
    }
    saveHtmlElementToPng(element, fileName);
};
/**
 * Save html element as png
 * See: https://github.com/bubkoo/html-to-image#readme
 * @param element
 * @param fileName
 * @returns
 */
export var saveHtmlElementToPng = function (element, fileName, options) {
    if (!element) {
        return;
    }
    toPng(element, options).then(function (dataUrl) {
        saveDataUrlAs(dataUrl, fileName !== null && fileName !== void 0 ? fileName : 'image.png');
    });
};
/**
 * Save table content as Excel file
 * @param id
 * @param title
 * @returns
 */
export var saveTableAsExcel = function (id, title) {
    var element = document.getElementById(id);
    var data = [];
    if (!element) {
        return;
    }
    var rows = element.querySelectorAll('table tr');
    for (var i = 0; i < rows.length; i++) {
        var row = [], cols = rows[i].querySelectorAll('td, th');
        for (var j = 0; j < cols.length; j++) {
            var td = cols[j];
            if (td.dataset.exportIgnore !== 'true') {
                // If data-value attribute has a value, use that instead of inner text
                if (td.dataset.value) {
                    row.push(!isNaN(Number(td.dataset.value))
                        ? Number(td.dataset.value)
                        : td.dataset.value);
                }
                else {
                    row.push(!isNaN(Number(td.innerText)) ? Number(td.innerText) : td.innerText);
                }
            }
        }
        data.push(row);
    }
    var workbook = utils.book_new();
    var worksheet = utils.aoa_to_sheet(data);
    utils.book_append_sheet(workbook, worksheet);
    writeFile(workbook, "".concat(title, ".xlsx"));
};
