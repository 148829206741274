var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { LocalizationContext } from '../../localization/LocalizationProvider';
import { formatDate } from '../../utils';
import { DatePickerContainer, DatePickerStyle } from './DateTimePicker';
export var MonthPicker = function (_a) {
    var date = _a.date, setDate = _a.setDate, disabled = _a.disabled, name = _a.name, placeholder = _a.placeholder, style = _a.style;
    var locale = useContext(LocalizationContext).locale;
    var _b = useState(date ? new Date(date) : null), selectedDate = _b[0], setSelectedDate = _b[1];
    var handleChange = function (date) {
        setSelectedDate(date);
        setDate(date);
    };
    useEffect(function () {
        setSelectedDate(date ? new Date(date) : null);
    }, [date]);
    return (_jsx(DatePickerContainer, { children: _jsx(YearPickerStyle, { disabled: disabled, name: name, selected: selectedDate, onChange: function (date) { return handleChange(date); }, showMonthYearPicker: true, placeholderText: placeholder, dateFormat: "MMMM", style: style, locale: locale, renderCustomHeader: renderCustomHeader }) }));
};
MonthPicker.displayName = 'MonthPicker';
var YearPickerStyle = styled(DatePickerStyle)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  input {\n    width: 100px;\n  }\n"], ["\n  input {\n    width: 100px;\n  }\n"])));
var renderCustomHeader = function (_a) {
    var date = _a.date;
    return (_jsxs("span", { children: [" ", formatDate(date, 'MMMM')] }));
};
var templateObject_1;
