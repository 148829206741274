import { useEffect, useState } from 'react';
export var getLocale = function () {
    return window.localStorage.getItem('locale');
};
export var useLocale = function (defaultLocale, supportedLocales) {
    var _a = useState(defaultLocale), locale = _a[0], setLocale = _a[1];
    var saveLocale = function (locale) {
        window.localStorage.setItem('locale', locale);
        setLocale(locale);
    };
    var switchLocale = function (locale) {
        supportedLocales.includes(locale) && saveLocale(locale);
    };
    useEffect(function () {
        var _a;
        var storedLocale = (_a = getLocale()) !== null && _a !== void 0 ? _a : defaultLocale;
        !!storedLocale && setLocale(storedLocale);
    }, []);
    return [locale, switchLocale];
};
