var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { DatePickerContainer, DatePickerStyle } from './DateTimePicker';
export var YearPicker = function (_a) {
    var date = _a.date, setDate = _a.setDate, disabled = _a.disabled, inverted = _a.inverted, min = _a.min, max = _a.max, name = _a.name, placeholder = _a.placeholder, style = _a.style;
    var _b = useState(date ? new Date(date) : null), selectedDate = _b[0], setSelectedDate = _b[1];
    var handleChange = function (date) {
        setSelectedDate(date);
        setDate(date);
    };
    useEffect(function () {
        setSelectedDate(date ? new Date(date) : null);
    }, [date]);
    return (_jsx(DatePickerContainer, { children: _jsx(YearPickerStyle, { disabled: disabled, inverted: inverted, name: name, minDate: min, maxDate: max, selected: selectedDate, onChange: function (date) { return handleChange(date); }, showYearPicker: true, placeholderText: placeholder, dateFormat: "yyyy", style: style }) }));
};
YearPicker.displayName = 'YearPicker';
var YearPickerStyle = styled(DatePickerStyle)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  input {\n    width: 80px;\n  }\n"], ["\n  input {\n    width: 80px;\n  }\n"])));
var templateObject_1;
