import { Theme } from '@dimatech/shared/lib/themes';
import { CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const DimatechLogo = ({ style }: { style?: CSSProperties }) => {
  return (
    <Logo style={style}>
      <DimatechLogoContent />
    </Logo>
  );
};

export const DimatechLogoFooter = ({ style }: { style?: CSSProperties }) => {
  return (
    <LogoFooter style={style}>
      <DimatechLogoContent />
    </LogoFooter>
  );
};

export const DimatechLogoHeader = ({ style }: { style?: CSSProperties }) => {
  return (
    <LogoHeader style={style}>
      <Link to="/">
        <DimatechLogoContent />
      </Link>
    </LogoHeader>
  );
};

const DimatechLogoContent = () => {
  return (
    <>
      <div>D</div>
      <div>Dimatech</div>
    </>
  );
};

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-end;

  font: ${({ theme }: { theme: Theme }) => theme.fonts.title};

  a {
    border: none;
    text-decoration: none;
  }

  div {
    padding: 0;
    height: fit-content;
    width: fit-content;
  }

  div:first-of-type {
    color: ${({ theme }: { theme: Theme }) => theme.colors.primary};
    font-weight: 500;
    font-size: 60px;
  }

  div:last-of-type {
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.s};
    margin-left: 3px;
  }
`;

const LogoFooter = styled(Logo)``;

const LogoHeader = styled(Logo)`
  margin-top: 8px;
  margin-right: 10px;
  line-height: inherit;

  div:first-of-type {
    font-size: 32px;
  }

  div:last-of-type {
    font-size: ${({ theme }: { theme: Theme }) => theme.fonts.size.xs};
    margin-left: 0px;
  }
`;
