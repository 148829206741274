var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// Get path to an item in a tree
export var getTreePathToItem = function (data, parentId) {
    var _a;
    var path = [parentId];
    var nextParentId = parentId;
    var _loop_1 = function () {
        var id = nextParentId;
        nextParentId = (_a = data.find(function (item) { return item.id === id; })) === null || _a === void 0 ? void 0 : _a.parentId;
        if (nextParentId) {
            path.push(nextParentId);
        }
    };
    while (nextParentId) {
        _loop_1();
    }
    return path.reverse();
};
export var listToTree = function (items, parentIds, filter) {
    var tree = items
        .filter(function (item) { var _a; return parentIds.includes((_a = item.parentId) !== null && _a !== void 0 ? _a : null) && (!filter || (filter === null || filter === void 0 ? void 0 : filter(item))); })
        .map(function (item) {
        var _a;
        return (__assign(__assign({}, item), { children: listToTree(items, [(_a = item.id) !== null && _a !== void 0 ? _a : null]) }));
    });
    return tree;
};
export var getAllChildren = function (items, parentIds, filter) {
    var parents = items.filter(function (item) { var _a; return parentIds.includes((_a = item.parentId) !== null && _a !== void 0 ? _a : null) && (!filter || (filter === null || filter === void 0 ? void 0 : filter(item))); });
    var result = __spreadArray([], parents, true);
    parents.forEach(function (parent) {
        var _a;
        result = result.concat(getAllChildren(items, [(_a = parent.id) !== null && _a !== void 0 ? _a : null]));
    });
    return result;
};
