var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Color, ThemeType } from './models';
// Base theme that other themes can override
export var base = {
    name: 'Default Light',
    type: ThemeType.Light,
    size: {
        viewMaxWidth: '1500px',
    },
    boxShadows: {
        default: '0 5px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.15)',
        light: '0 5px 10px rgba(0, 0, 0, 0.05), 0 3px 3px rgba(0, 0, 0, 0.06)',
    },
    colors: {
        primary: Color.DimiosBlue,
        primaryVariant: Color.DimiosBlueDark,
        onPrimary: Color.White,
        secondary: Color.SeaGreen,
        secondaryVariant: Color.SeaGreenDark,
        onSecondary: Color.White,
        secondaryDim: Color.SeaGreenLight,
        secondaryDimVariant: Color.SeaGreenLight,
        onSecondaryDim: Color.SeaGreen,
        background: Color.Grey95,
        onBackground: Color.Grey20,
        surface: Color.White,
        onSurface: Color.Grey20,
        surfaceVariant: Color.Grey98,
        onSurfaceVariant: Color.Grey20,
        error: Color.MonzaRed,
        onError: Color.White,
        warning: Color.PorticaYello,
        onWarning: Color.Grey20,
        info: Color.Eminence,
        onInfo: Color.White,
        success: Color.SeaGreen,
        onSuccess: Color.White,
        lowPriority: Color.Grey80,
        onLowPriority: Color.Grey20,
        border: Color.Grey80,
        shadow: Color.Grey80,
        dim: Color.Grey60,
        chart: {
            primary: Color.DimiosBlue,
            primaryHighlight: Color.DimiosBlueDark,
            secondary: Color.SeaGreen,
            secondaryHighlight: Color.SeaGreenDark,
            gridStroke: Color.Grey60,
            axisStroke: Color.Grey20,
            colors: [
                Color.DimiosBlue,
                Color.SandyBrown,
                Color.NightShadz,
                Color.Eminence,
                Color.RadicalRed,
                Color.BrightTurquoise,
                Color.KojiOrange,
                Color.PorticaYello,
                Color.SeaGreen,
            ],
            scale: [
                Color.NightShadz,
                Color.RadicalRed,
                Color.KojiOrange,
                Color.PorticaYello,
                Color.SeaGreen,
                Color.BrightTurquoise,
            ],
        },
    },
    fonts: {
        base: "normal 400 1.3rem/21px 'Roboto', sans-serif",
        title: "normal 300 2.6rem/32px 'Roboto', sans-serif",
        h1: "normal 300 2.4rem/32px 'Roboto', sans-serif",
        h2: "normal 300 2.1rem/28px 'Roboto', sans-serif",
        h3: "normal 300 1.8rem/24px 'Roboto', sans-serif",
        h4: "normal 300 1.6rem/24px 'Roboto', sans-serif",
        size: {
            base: '1.3rem',
            s: '1.2rem',
            xs: '1.1rem',
            xxs: '1rem',
            l: '1.4rem',
            xl: '1.6rem',
            xxl: '1.8rem',
        },
    },
};
export var baseDark = __assign(__assign({}, base), { name: 'Default Dark', type: ThemeType.Dark, colors: __assign(__assign({}, base.colors), { background: Color.Grey10, onBackground: Color.White, surface: Color.Grey15, onSurface: Color.White, surfaceVariant: Color.Grey20, onSurfaceVariant: Color.White, border: Color.Grey90, chart: __assign(__assign({}, base.colors.chart), { gridStroke: Color.Grey20, axisStroke: Color.Grey60, secondary: Color.KojiOrange }) }) });
