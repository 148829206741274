import { useEffect, useState } from 'react';
export var useThemeDetector = function () {
    var matcher = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');
    var _a = useState(!!(matcher === null || matcher === void 0 ? void 0 : matcher.matches)), isUsingDarkTheme = _a[0], setIsUsingDarkTheme = _a[1];
    var handleThemeChange = function (event) {
        setIsUsingDarkTheme(event.matches);
    };
    useEffect(function () {
        matcher === null || matcher === void 0 ? void 0 : matcher.addEventListener('change', handleThemeChange);
        return function () { return matcher === null || matcher === void 0 ? void 0 : matcher.removeEventListener('change', handleThemeChange); };
    }, []);
    return isUsingDarkTheme;
};
