import { Breakpoints, Theme } from '@dimatech/shared/lib/themes';
import { Container, ContainerWrapper } from 'components/Container';
import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

export const HeroProduct = ({
  image,
  children,
  style,
}: {
  image: string;
  children?: ReactNode;
  style?: CSSProperties;
}): JSX.Element => {
  return (
    <ContainerWrapper>
      <Container>
        <Style image={image} style={style}>
          <Container>{children}</Container>
        </Style>
      </Container>
    </ContainerWrapper>
  );
};

HeroProduct.displayName = 'HeroProduct';

const Style = styled.div<{
  readonly image: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: ${({ theme }: { theme: Theme }) => theme.size.viewMaxWidth};
  padding-left: 20px !important;

  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: 100% 30%;

  min-height: 200px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;

    padding: 20px;
    padding-left: 0;
  }

  @media (min-width: ${Breakpoints.medium}) {
    background-position: 100% 15%;
    min-height: 350px;
    padding-left: 50px !important;

    > div {
      gap: 10px;
    }
  }

  @media (min-width: ${Breakpoints.large}) {
    min-height: 550px;

    > div {
      gap: 20px;
    }
  }
`;
