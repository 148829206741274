var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { Sorter } from './Sorter';
export var SortElement = function (_a) {
    var sorter = _a.sorter, sortKey = _a.sortKey, sortPredicate = _a.sortPredicate, children = _a.children, style = _a.style, className = _a.className;
    return (_jsxs(Style, __assign({ className: className, style: __assign({ cursor: 'pointer', overflow: 'hidden' }, style), onClick: function () { return sorter.requestSort(sortKey, sortPredicate); } }, { children: [children, _jsx(Sorter, { sorter: sorter, sortKey: sortKey })] })));
};
SortElement.displayName = 'SortElement';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-flex;\n\n  > span {\n    margin-left: 5px;\n    width: 15px;\n    display: inline-block;\n  }\n"], ["\n  display: inline-flex;\n\n  > span {\n    margin-left: 5px;\n    width: 15px;\n    display: inline-block;\n  }\n"])));
var templateObject_1;
