import { defaultOptions } from '@dimatech/shared/lib/localization';
import common_shared_en from '@dimatech/shared/lib/localization/lang/en.json';
import common_shared_sv from '@dimatech/shared/lib/localization/lang/sv.json';
import { Language, Translations } from '@dimatech/shared/lib/models';
import { mergeJson } from '@dimatech/shared/lib/utils';
import { enGB as en, sv } from 'date-fns/locale';
import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { registerLocale } from 'react-datepicker';
import { initReactI18next } from 'react-i18next';
import common_en from './lang/en.json';
import common_sv from './lang/sv.json';

registerLocale(Language.English, en);
registerLocale(Language.Swedish, sv);

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init(defaultOptions);

// Merge all shared language files with the language files in this app
i18n.addResourceBundle(
  Language.Swedish,
  Translations.Common,
  mergeJson(common_shared_sv, [common_sv])
);

i18n.addResourceBundle(
  Language.English,
  Translations.Common,
  mergeJson(common_shared_en, [common_en])
);

export default i18n;
