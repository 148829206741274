var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { LoaderContent, LoaderStyle } from './Loader';
export var LoaderOverlay = function (_a) {
    var children = _a.children, style = _a.style;
    return (_jsxs(Container, { children: [_jsx(LoaderStyle, { style: style }), children && _jsx(Content, { children: children })] }));
};
LoaderOverlay.displayName = 'LoaderOverlay';
var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n  min-height: 50px;\n  left: 0;\n  top: 0;\n\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  justify-content: space-between;\n\n  position: absolute;\n  z-index: 100;\n\n  overflow: auto;\n  background-color: rgb(0, 0, 0, 0.5);\n  justify-content: center;\n"], ["\n  width: 100%;\n  height: 100%;\n  min-height: 50px;\n  left: 0;\n  top: 0;\n\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  justify-content: space-between;\n\n  position: absolute;\n  z-index: 100;\n\n  overflow: auto;\n  background-color: rgb(0, 0, 0, 0.5);\n  justify-content: center;\n"])));
var Content = styled(LoaderContent)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", ";\n"], ["\n  font-size: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.fonts.size.s;
});
var templateObject_1, templateObject_2;
