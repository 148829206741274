var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TooltipStyle } from './Tooltip';
export var ChartTooltip = function (_a) {
    var active = _a.active, payload = _a.payload, formatter = _a.formatter;
    if (active && payload && payload.length) {
        return (_jsxs(TooltipStyle, { children: [payload[0].payload.label && (_jsx("div", __assign({ className: "tooltip-title" }, { children: payload[0].payload.label }))), payload.map(function (row, index) { return (_jsx("div", { children: "".concat(row.name, ": ").concat(formatter && formatter(row.value)) }, index)); })] }));
    }
    return null;
};
ChartTooltip.displayName = 'ChartTooltip';
