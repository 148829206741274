var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../config';
import { useLocale } from './useLocale';
export var LocalizationContext = createContext({});
export var LocalizationProvider = function (_a) {
    var defaultLanguage = _a.defaultLanguage, children = _a.children;
    var locales = config.localisation.supportedLanguages;
    var _b = useLocale(defaultLanguage !== null && defaultLanguage !== void 0 ? defaultLanguage : locales[0], locales), locale = _b[0], switchLocale = _b[1];
    var i18n = useTranslation().i18n;
    var changeLocale = function (locale) {
        switchLocale(locale);
        i18n.changeLanguage(locale);
    };
    return (_jsx(LocalizationContext.Provider, __assign({ value: {
            locale: locale,
            changeLocale: changeLocale,
        } }, { children: children })));
};
