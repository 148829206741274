import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
export var ScrollToTop = function (_a) {
    var scrollToTop = _a.scrollToTop, _b = _a.behavior, behavior = _b === void 0 ? 'instant' : _b;
    var pathname = useLocation().pathname;
    useEffect(function () {
        if (scrollToTop) {
            window.scrollTo({
                top: 0,
                left: 0,
                // @ts-expect-error https://github.com/microsoft/TypeScript-DOM-lib-generator/issues/1195
                // eslint-disable-next-line object-shorthand
                behavior: behavior,
            });
        }
    }, [pathname, scrollToTop]);
    return null;
};
