var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '../../themes';
import { Heading3 } from '../typography';
export var Modal = function (_a) {
    var title = _a.title, children = _a.children, style = _a.style, handleKeyEnter = _a.handleKeyEnter, handleKeyEsc = _a.handleKeyEsc;
    useEffect(function () {
        window.addEventListener('keydown', handleKeyDown, false);
        return function () {
            window.removeEventListener('keydown', handleKeyDown, false);
        };
    }, []);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var handleKeyDown = function (e) {
        if ((e === null || e === void 0 ? void 0 : e.key) === 'Enter' && handleKeyEnter) {
            handleKeyEnter(e);
            return;
        }
        if ((e === null || e === void 0 ? void 0 : e.key) === 'Escape' && handleKeyEsc) {
            handleKeyEsc(e);
            return;
        }
    };
    return (_jsx(Style, { children: _jsxs(ModalCanvas, __assign({ style: style, onClick: function (e) {
                e.stopPropagation();
            } }, { children: [title && (_jsx("div", { children: _jsx(Heading3, { children: title }) })), _jsx("div", { children: children })] })) }));
};
Modal.displayName = 'Modal';
var Style = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-width: 100% !important;\n  width: 100% !important;\n  height: 100%;\n  left: 0;\n  top: 0;\n\n  position: fixed;\n  z-index: 100;\n\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n\n  padding-top: 30px;\n\n  overflow: auto;\n  background-color: rgb(0, 0, 0, 0.5);\n  justify-content: center;\n"], ["\n  max-width: 100% !important;\n  width: 100% !important;\n  height: 100%;\n  left: 0;\n  top: 0;\n\n  position: fixed;\n  z-index: 100;\n\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n\n  padding-top: 30px;\n\n  overflow: auto;\n  background-color: rgb(0, 0, 0, 0.5);\n  justify-content: center;\n"])));
export var ModalCanvas = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 500px;\n  max-width: 900px;\n  min-height: 80px;\n\n  margin: auto;\n  display: block;\n  text-align: left;\n\n  background-color: ", ";\n  color: ", ";\n  border-radius: 3px;\n\n  > div {\n    padding: 15px;\n  }\n\n  > div:first-of-type {\n    background-color: ", ";\n    color: ", ";\n  }\n\n  > div:last-of-type {\n    height: 85%;\n    width: 100%;\n  }\n\n  @media screen and (max-width: ", ") {\n    width: 300px;\n  }\n"], ["\n  width: 500px;\n  max-width: 900px;\n  min-height: 80px;\n\n  margin: auto;\n  display: block;\n  text-align: left;\n\n  background-color: ", ";\n  color: ", ";\n  border-radius: 3px;\n\n  > div {\n    padding: 15px;\n  }\n\n  > div:first-of-type {\n    background-color: ", ";\n    color: ", ";\n  }\n\n  > div:last-of-type {\n    height: 85%;\n    width: 100%;\n  }\n\n  @media screen and (max-width: ", ") {\n    width: 300px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.surface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onSurface;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.background;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.onBackground;
}, Breakpoints.small);
ModalCanvas.displayName = 'ModalCanvas';
var templateObject_1, templateObject_2;
