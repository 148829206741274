var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '../form';
import { Modal } from '../modal';
export var UserAgreement = function (_a) {
    var setIsShowingAgreement = _a.setIsShowingAgreement, userAgreement = _a.userAgreement;
    var t = useTranslation().t;
    return (_jsxs(Modal, __assign({ handleKeyEsc: function () { return setIsShowingAgreement(false); }, style: { width: '90%', maxWidth: 700 }, title: t('UserAgreement.Title') }, { children: [_jsx("div", __assign({ style: { overflowY: 'scroll', height: '80%', maxHeight: 700 } }, { children: userAgreement })), _jsx(Buttons, { children: _jsx(Button, __assign({ style: { minWidth: 100 }, type: "button", onClick: function () { return setIsShowingAgreement(false); } }, { children: t('Common.UI.Ok') })) })] })));
};
UserAgreement.displayName = 'UserAgreement';
var Buttons = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  button,\n  a {\n    margin-top: 20px;\n  }\n"], ["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  button,\n  a {\n    margin-top: 20px;\n  }\n"])));
var templateObject_1;
