import { Heading2 } from '@dimatech/shared/lib/components/typography';
import { useTranslation } from 'react-i18next';
import { HeroBox } from './HeroBox';

export const PoliciesBox = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <HeroBox style={{ minHeight: 250 }}>
      <Heading2>{t('Policies.Title')}</Heading2>
    </HeroBox>
  );
};
