import { Heading3 } from '@dimatech/shared/lib/components/typography';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ProductBox = ({
  image,
  title,
  children,
  linkTo,
  linkText,
  altText,
}: {
  image: string;
  title: string;
  children: React.ReactNode;
  linkTo: string;
  linkText: string;
  altText: string;
}): JSX.Element => {
  return (
    <Style>
      <img src={image} alt={altText} />

      <div>
        <Heading3>{title}</Heading3>
        <div>{children}</div>

        <Link to={`/${linkTo}`}>{linkText}</Link>
      </div>
    </Style>
  );
};

const Style = styled.div`
  display: flex;
  flex-direction: column;

  > img {
    width: 100%;
  }

  > div {
    padding: 20px;

    h3 {
      margin-top: 20px;
    }

    a {
      font-weight: 700;
    }
  }

  @media (min-width: 1025px) {
    > img {
      padding: 20px;
    }

    > div {
      padding: 0 60px 20px 60px;
    }
  }
`;
